import {Banner, LabelButton} from '@Eni/docware-fe-master';
import {Spinner} from '@fluentui/react';
import {useAppInsightsContext} from '@microsoft/applicationinsights-react-js';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import AttachmentSmallBrowserButton from '../../Components/AttachmentSmallBrowserButton/AttachmentSmallBrowserButton';
import {DriveDocumentManagementToolbar} from '../../Components/DriveDocumentManagementToolbar/DriveDocumentManagementToolbar';
import StatusBubble from '../../Components/StatusBubble/StatusBubble';
import Tab, {TabbedListTab} from '../../Components/Tab/Tab';
import {FileInfo} from '../../Models/FileUtils';
import {IWorkflowItem, IWorkflowUser, WorkflowSignatureAction} from '../../Models/IWorkflowItem';
import {GenericActions} from '../../Reducers/Generic/GenericAction';
import {GlobalState} from '../../Reducers/RootReducer';
import {IAPIResponse} from '../../Services/AjaxService';
import ApiService from '../../Services/ApiService';
import AppRoutes from '../../Utils/AppRoutes';
import {getDocumentLabel, getDocumentLabelString} from '../../Utils/ComponentsUtils';
import {getTrackingComponent} from '../../AppInsights';
import './ActivitiesPage.scss';
import VideoTutorialIcon from '../../Components/VideoTutorialIcon/VideoTutorialIcon';
import GenericList from '../../Components/GenericList/GenericList';

const workflowSignStatusToDocumentStatus = (wfStatus: number) => {
  if (wfStatus === 0) {
    return 2;
  }
  if (wfStatus === 1) {
    return 1;
  }
  if (wfStatus === 2) {
    return 3;
  }
  return -1;
};

const ActivitiesPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /** application insight metrics */
  useAppInsightsContext().trackMetric(
    {
      average: 1,
      name: 'React Component Engaged Time (seconds)',
      sampleCount: 1,
    },
    {'Component Name': 'ActivitiesPage'}
  );
  const activities = useSelector((state: GlobalState) => state.generic.tasks);
  const [loading, setLoading] = useState<boolean>(true);
  const [docsWithAttach, setDocsWithAttach] = useState<{documentId: string; attachments: FileInfo[]}[]>([]);
  const [loadAmount, setLoadAmount] = useState<number>(1000);
  const tabs = [
    {
      label: `Pending documents`,
      iconName: 'ReminderTime',
      key: WorkflowSignatureAction.notSigned,
      color: 'gray',
      matchDataOnField: 'userSignStatus',
    },
    {
      label: 'Rejected documents',
      iconName: 'Error',
      key: WorkflowSignatureAction.rejected,
      color: 'rgba(198,32,36)',
      matchDataOnField: 'userSignStatus',
    },
    {
      label: 'Signed documents',
      iconName: 'PenWorkspace',
      key: WorkflowSignatureAction.signed,
      color: 'rgb(39,114,113)',
      matchDataOnField: 'userSignStatus',
    },
  ];
  const [activeTab, setActiveTab] = useState<TabbedListTab>(tabs[0]);

  useEffect(() => {
    if (loadAmount > activities.length && activities.length >= 1000) {
      loadActivitiesAndAttachments(activeTab.key);
    } else {
      setLoading(false);
    }
  }, [loadAmount]);

  const loadActivitiesAndAttachments = (signType: WorkflowSignatureAction) => {
    setLoading(true);
    ApiService.WorkflowController.getWorkflowHistoryWithAttachments(loadAmount, signType, (response: IAPIResponse) => {
      if (response.error == null) {
        const activities: IWorkflowItem[] = response.payload.activities;
        dispatch(GenericActions.SetTasksBackground(activities));
        setDocsWithAttach(response.payload.attachments);
        setLoading(false);
      }
    });
  };

  const onTabChange = (activeTab: TabbedListTab) => {
    loadActivitiesAndAttachments(activeTab.key);
    setActiveTab(activeTab);
  };

  const getSignersByStep = (stepNumber: number, item: IWorkflowItem): string => {
    const _formatUser = (user: IWorkflowUser): string => {
      let status = '';
      switch (user.userSignStatus) {
        case WorkflowSignatureAction.signed:
          status = 'Signed';
          break;
        case WorkflowSignatureAction.notSigned:
          status = 'Not Signed';
          break;
        case WorkflowSignatureAction.rejected:
          status = 'Rejected';
          break;
      }
      return `${user.name} (${status})`;
    };

    const step = item.signers.filter((x) => x.order === stepNumber - 1);
    if (step.length > 0) {
      const usersFormatted: string[] = step[0].users.reduce<string[]>((acc, user) => {
        acc.push(_formatUser(user));
        user.delegates.forEach((delegate) => acc.push(_formatUser(delegate)));
        return acc;
      }, []);
      return usersFormatted.join(', ');
    } else {
      return '-';
    }
  };

  return (
    <div className="general-page-container">
      <DriveDocumentManagementToolbar type={'navigation-voices'} />
      <div className="activity-page-main-wrap">
        <div className="activity-page-title">
          Your Tasks
          <VideoTutorialIcon />
        </div>
        <div className="activity-page-subtitle">Browse the Approval Workflows where you figure as Signing Authority.</div>
        <div style={{padding: '1em'}}>
          <Banner
            enabled
            type="info"
            message={
              'Document migration from MyDoc to Docware Drive is currently in progress. You may not find some documents on the new platform: consult MyDoc in case of missing files.'
            }
          />
        </div>
        <Tab tabs={tabs} onTabChange={onTabChange} />

        {loading && (
          <div className="activity-page-spinner-wrap-main">
            <div className="activity-page-spinner-inner">
              <Spinner label="Loading..." />
            </div>
          </div>
        )}
        {!loading && (
          <div>
            <GenericList
              iconFromField={'mainDocumentName'}
              allowFilter={false}
              columns={[
                {
                  name: 'File Type',
                  dataType: 'string',
                  fieldName: 'name',
                  iconName: 'Page',
                  size: 'small',
                },
                {
                  name: 'Name',
                  dataType: 'string',
                  fieldName: 'documentName',
                  iconName: null,
                  size: 'large',
                  onRender: (item: IWorkflowItem) => {
                    let navUrl: string = AppRoutes.DOCUMENT_MANAGEMENT + '?documentid=' + item.documentId.toString();

                    let properDocRefs: {documentId: string; attachments: FileInfo[]}[] = docsWithAttach.filter(
                      (x: {documentId: string; attachments: FileInfo[]}) => x.documentId === item.documentId
                    );
                    let properDocRef: {documentId: string; attachments: FileInfo[]} = {documentId: null, attachments: null};
                    if (properDocRefs.length > 0) {
                      properDocRef = properDocRefs[0];
                    }

                    return (
                      <div className="file-name-column-cell">
                        <span
                          title={getDocumentLabelString(item.documentName, null)}
                          className="file-name-column-cell-buttons-label file-linkname"
                          onClick={() => {
                            navigate(navUrl);
                          }}>
                          {getDocumentLabel(item.documentName, null, navUrl)}
                          <div className="file-name-file-inline-button">
                            <AttachmentSmallBrowserButton
                              explicitDocumentId={properDocRef.documentId}
                              explicitAttachmentList={properDocRef.attachments}
                            />
                          </div>
                        </span>
                      </div>
                    );
                  },
                },
                {
                  name: 'Created by',
                  dataType: 'string',
                  fieldName: 'createdBy.name',
                  iconName: null,
                  size: 'medium',
                },
                {
                  name: 'Status',
                  dataType: 'string',
                  fieldName: 'userSignStatus',
                  iconName: null,
                  size: 'medium',
                  onRender: (item: IWorkflowItem) => {
                    return <StatusBubble isOldVersion={false} documentStatus={workflowSignStatusToDocumentStatus(item.userSignStatus)} />;
                  },
                },
                {
                  name: 'Step 1',
                  dataType: 'string',
                  fieldName: '',
                  iconName: null,
                  size: 'medium',
                  isMultiline: true,
                  onRender: (item: IWorkflowItem) => {
                    return getSignersByStep(1, item);
                  },
                },
                {
                  name: 'Step 2',
                  dataType: 'string',
                  fieldName: '',
                  iconName: null,
                  size: 'medium',
                  isMultiline: true,
                  onRender: (item: IWorkflowItem) => {
                    return getSignersByStep(2, item);
                  },
                },
                {
                  name: 'Step 3',
                  dataType: 'string',
                  fieldName: '',
                  iconName: null,
                  size: 'medium',
                  isMultiline: true,
                  onRender: (item: IWorkflowItem) => {
                    return getSignersByStep(3, item);
                  },
                },
                {
                  name: 'Step 4',
                  dataType: 'string',
                  fieldName: '',
                  iconName: null,
                  size: 'medium',
                  isMultiline: true,
                  onRender: (item: IWorkflowItem) => {
                    return getSignersByStep(4, item);
                  },
                },
                {
                  name: 'Step 5',
                  dataType: 'string',
                  fieldName: '',
                  iconName: null,
                  size: 'medium',
                  isMultiline: true,
                  onRender: (item: IWorkflowItem) => {
                    return getSignersByStep(5, item);
                  },
                },
                {
                  name: 'Step 6',
                  dataType: 'string',
                  fieldName: '',
                  iconName: null,
                  size: 'medium',
                  isMultiline: true,
                  onRender: (item: IWorkflowItem) => {
                    return getSignersByStep(6, item);
                  },
                },
              ]}
              items={activities}
            />

            {activities.length === loadAmount && (
              <div className="activity-page-centered-button">
                <LabelButton
                  whiteOutlined
                  icon="ChevronDown"
                  text="Load more"
                  onClick={() => {
                    setLoadAmount(loadAmount + 500);
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default getTrackingComponent(ActivitiesPage, 'ActivitiesPage');
