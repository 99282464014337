import {removeDuplicatesFromArrayOfObject} from '../../Utils/DictUtils';
import {IBoRow} from './Model';

/**
 * Merge two IBoRow array. Note that each array must have a unique key
 */
export function mergeIBoRow(firstList: IBoRow[], secondList: IBoRow[]):IBoRow[] {
  const output: IBoRow[] = []
  firstList.forEach((firstListEl) => {
    const secondMatch = secondList.filter(x => x.key == firstListEl.key)[0];
    if(secondMatch){
      output.push({...firstListEl, values: firstListEl.values.concat(secondMatch.values)})
    } else {
      output.push(firstListEl);
    }
  })
  secondList.forEach((secondListEl) => {
    const firstMatch = firstList.filter(x => x.key == secondListEl.key)[0];
    if(firstMatch){
      // Already added in the previus loop
    } else {
      output.push(secondListEl);
    }
  })

  const outputCleanDuplicatedValues = output.map((el) => {
    el.values = removeDuplicatesFromArrayOfObject(el.values, "id")
    return el;
  })

  return outputCleanDuplicatedValues;
}