import { Banner, LabelButton, ModalDialog } from '@Eni/docware-fe-master';
import { ComboBox, Dropdown, Spinner, Toggle } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getListOfSelectedKeysMultiSelect } from '../../../Components/FluentUIDecorator/FluentUIDecorator';
import GenericList from '../../../Components/GenericList/GenericList';
import { UserInfoTable } from '../../../Components/UserInfoTable/UserInfoTable';
import { IElementTypes, ILibrary, IProfessionalArea, LibraryFunctions } from '../../../Models/ILibrary';
import { IPermissionRecap, IUser } from '../../../Models/IUser';
import { AdminAction } from '../../../Reducers/Admin/AdminActions';
import { GlobalState } from '../../../Reducers/RootReducer';
import { IAPIResponse } from '../../../Services/AjaxService';
import ApiService from '../../../Services/ApiService';
import { getLastLoginDate } from '../../../Utils/DateUtils';
import SignatureUtils from '../../../Utils/SignatureUtils';
import './UsersList.scss';

export const arrayUnique = (array: any[]) => {
  let a = array.concat();
  for (let i = 0; i < a.length; ++i) {
    for (let j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1);
    }
  }
  return a;
};

const ExportListAsExcel = (title: string, header: string[], rows: string[][]) => {
  let csvContent: string[] = [header.join(';')];
  for (let i = 0; i < rows.length; i++) {
    csvContent.push(rows[i].join(';'));
  }

  let csvRows = 'sep=;\n' + csvContent.join('\n');

  let url = URL.createObjectURL(
    new Blob([csvRows], {
      type: 'text/csv',
    })
  );

  let link: any = document.createElement('a');
  link.download = title + '.csv';
  link.href = url;
  link.click();
};

var reopenUserTarg = '';

const UsersList = (props: any) => {
  const dispatch = useDispatch();
  const [adminLibraries, setAdminLibraries] = useState<ILibrary[]>([]);
  const [showManage, setShowManage] = useState<'' | 'etypes' | 'pareas'>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [reopenUser, setReopenUser] = useState<string>('');
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [runPermSync, setRunPermSync] = useState<number>(0);
  const [savingUserManage, setSavingUserManage] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<any | null>(null);
  const [filter1, setFilter1] = useState<string | null>(null);
  const [filter1arg, setFilter1arg] = useState<{key: string; text: string}[]>([]);
  const [filter2, setFilter2] = useState<string | null>(null);
  const [filter2arg, setFilter2arg] = useState<{key: string; text: string}[]>([]);
  const [textFilter, setTextFilter] = useState<string>('');
  const [permissionRecap, setPermissionRecap] = useState<IPermissionRecap | null>(null);
  const [loadingPermissionRecap, setLoadingPermissionRecap] = useState<boolean>(false);
  const loggedUser = useSelector((state: GlobalState) => state.user.currentUser);
  const [comboElTypes, setComboElTypes] = useState<string[]>([]);
  const [comboProfArea, setComboProfArea] = useState<string[]>([]);
  const [usersBase, setUsersBase] = useState<any[]>([]);
  const [maxOnThisSearch, setMaxOnThisSearch] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(20);

  const [usersDisplay, setUsersDisplay] = useState<any[]>([]);

  useEffect(() => {
    ApiService.LibraryController.getAdminLibraries((response: IAPIResponse) => {
      if (response.error == null) {
        setAdminLibraries(response.payload);
      }
    });
    loadAllFilters();

    ApiService.CountriesController.getCountries((response: IAPIResponse) => {
      if (response.error == null) {
        dispatch(AdminAction.SetUnselectAdminCountries(response.payload));
      }
    })
  }, []);

  useEffect(() => {
    if (editItem) {

      setLoadingPermissionRecap(true);
      ApiService.UsersController.getPermissionRecapAdmin(editItem.id, (response: IAPIResponse) => {
        if (response.error === null) {
          setPermissionRecap(response.payload);
        }
        setLoadingPermissionRecap(false);
      });
    }
  }, [editItem]);

  useEffect(() => {
    if (editItem) {
      setComboElTypes(editItem.elementTypes);
      setComboProfArea(editItem.professionalAreas);
    }
  }, [editItem]);

  useEffect(() => {
    getAllUsers();
  }, [textFilter, filter1, filter2, perPage, page]);

  const getAllUsers = (allowLoading: boolean = true) => {
    if (allowLoading) {
      setLoading(true);
    }
    ApiService.UsersController.getAllUsers(
      {
        search: textFilter,
        skip: perPage * page,
        limit: perPage,
        elementType: filter1,
        professionalAreaId: filter2,
      },
      (response: IAPIResponse) => {
        setLoading(false);
        if (response.error == null) {
          setUsersBase([]);
          setUsersBase(response.payload.users);
          setMaxOnThisSearch(response.payload.userCount);

          let usr_ = response.payload.users.filter((x: any) => x.id === reopenUserTarg);
          if (usr_.length > 0) {
            let u = usr_[0];
            setEditItem({...u});
            setShowEdit(true);
          }
        }
        setReopenUser('');
        reopenUserTarg = '';
      }
    );
  };

  const UserInfo = () => {
    if (loadingPermissionRecap) {
      return (
        <div className="loading-permission-spinner">
          <Spinner label="Loading permissions..." />
        </div>
      );
    }

    if (editItem && permissionRecap) {
      return (
        <UserInfoTable
          userId={editItem.id}
          name={editItem.joinedName}
          email={editItem.email}
          digitalSignature={SignatureUtils.getSignatureVisualization(
            permissionRecap.digitalSignatureType,
            permissionRecap.digitalSignatureData
          )}
          lastLogin={getLastLoginDate(permissionRecap.lastLogin)}
          libraries={permissionRecap.libraries}
          adminLibraries={adminLibraries}
          adminSectionOnSave={fallBackSave}
        />
      );
    }

    return (
      <div>
        <Banner message={'Could not load user permissions.'} type="error" enabled={true} />
      </div>
    );
  };

  const parseUserRecord = (user: any) => {
    // process COUNTRIES
    let countriesNodes = [];
    let countriesAmount = 0;
    let countries = user.countries;
    for (let i = 0; i < countries.length; i++) {
      let _c = countries[i];
      let countryCleanList = [];
      for (let j = 0; j < _c.countries.length; j++) {
        countryCleanList.push(_c.countries[j].countryDescription);
        countriesAmount++;
      }

      countriesNodes.push(
        <div key={i}>
          <div className="list-user-node-title">{_c.permissionModel}</div>
          <div className="list-user-node-content">{countryCleanList.length > 0 ? countryCleanList.join(', ') : '-'}</div>
        </div>
      );
    }

    // process LIBRARIES & PROF. AREAS
    let professionalAreasFullNamesTmp = [];
    let professionalAreasFullNames = [];
    let professionalAreasNames = [];
    let librariesNames = [];

    let libraries = user.libraries;

    for (let i = 0; i < libraries.length; i++) {
      let _l = libraries[i];
      librariesNames.push(_l.name);

      for (let j = 0; j < _l.professionalAreas.length; j++) {
        let _a = _l.professionalAreas[j];
        professionalAreasFullNamesTmp.push(
          <span key={j}>
            {_a.name}{' '}
            <span>
              <small>{'(' + _l.name + ')'}</small>
            </span>
          </span>
        );
        professionalAreasNames.push(_a.name);
      }
    }

    for (let i = 0; i < professionalAreasFullNamesTmp.length; i++) {
      professionalAreasFullNames.push(
        <span key={i}>
          {professionalAreasFullNamesTmp[i]}
          <span>{i < professionalAreasFullNamesTmp.length - 1 ? ', ' : ''}</span>
        </span>
      );
    }

    let parsed: any = {...user};

    parsed['joinedName'] = user.firstName + ' ' + user.lastName;
    parsed['librariesParsedStr'] = librariesNames.length > 0 ? librariesNames.join(', ') : '-';
    parsed['professionalAreasParsedStr'] = professionalAreasNames.length > 0 ? professionalAreasNames.join(', ') : '-';
    parsed['countriesParsedStr'] = countriesAmount.toString();
    parsed['elementTypeParsedStr'] = user.elementTypes.length > 0 ? user.elementTypes.join(', ') : '-';

    parsed['librariesParsed'] = librariesNames.length > 0 ? librariesNames.join(', ') : '-';
    parsed['professionalAreasParsed'] = professionalAreasFullNames.length > 0 ? professionalAreasFullNames : '-';
    parsed['countriesParsed'] = countriesNodes.length > 0 ? countriesNodes : '-';
    parsed['elementTypeParsed'] = user.elementTypes.length > 0 ? user.elementTypes.join(', ') : '-';

    parsed['email_'] = user.email && user.email !== '' ? user.email : '-';

    if (user.isSuperAdmin) {
      parsed['professionalAreasParsedStr'] = 'All';
      parsed['countriesParsedStr'] = 'All';
      parsed['elementTypeParsedStr'] = 'All';
      parsed['librariesParsedStr'] = 'All';
    }

    return parsed;
  };

  useEffect(() => {
    let users_: any[] = [];
    for (let i = 0; i < usersBase.length; i++) {
      let u: IUser = usersBase[i];
      users_.push(parseUserRecord(u));
    }
    setUsersDisplay(users_);
  }, [adminLibraries, usersBase]);

  const loadAllFilters = () => {
    ApiService.UsersController.adminFiltersGet((response: IAPIResponse) => {
      if (response.error === null) {
        setAvailableAreasList(response.payload.professionalAreas);
        setAvailableElementTypesList(response.payload.elementTypes);
      }
    });
  };

  const setAvailableElementTypesList = (elementTypeList: string[]) => {
    let elTypes = [{key: '__all__', text: 'All'}];
    for (let i = 0; i < elementTypeList.length; i++) {
      let el = elementTypeList[i];
      elTypes.push({key: el, text: el});
    }
    setFilter1arg(elTypes);
  };

  const setAvailableAreasList = (profAreas: {name: string; id: string}[]) => {
    let professionalAreasNames = [{key: '__all__', text: 'All'}];
    for (let j = 0; j < profAreas.length; j++) {
      let _a: {name: string; id: string} = profAreas[j];
      professionalAreasNames.push({key: _a.id, text: _a.name});
    }
    setFilter2arg(professionalAreasNames);
  };

  const allProfessionalAreas: IProfessionalArea[] = LibraryFunctions.getProfessionalAreas(adminLibraries);
  const allElementTypes: IElementTypes[] = LibraryFunctions.getElementTypes(adminLibraries);
  const manageableProfessionalAreas: IProfessionalArea[] = allProfessionalAreas.filter((x) => x.userManagementEnabled);
  const manageableElementTypes: IElementTypes[] = allElementTypes.filter((x) => x.isManuallyManaged);

  const GetComboOptions = () => {
    if (showManage === 'pareas') {
      return manageableProfessionalAreas.map((x: IProfessionalArea) => {
        return {key: x.id, text: x.name};
      });
    }
    if (showManage === 'etypes') {
      return manageableElementTypes.map((x: IElementTypes) => {
        return {key: x.id, text: x.name};
      });
    }
    return [];
  };

  let comboOptions = GetComboOptions();

  const ProfessionalAreasIdToNames = (profAreaIdList: string[], profAreas: IProfessionalArea[]) => {
    return profAreas
      .filter((x: IProfessionalArea) => profAreaIdList.indexOf(x.id) !== -1)
      .map((x: IProfessionalArea) => {
        return x.name;
      });
  };

  const ElementTypesIdToNames = (elementTypesIds: string[], elementTypes: IElementTypes[]) => {
    return elementTypes
      .filter((x) => elementTypesIds.indexOf(x.id) !== -1)
      .map((x) => {
        return x.name;
      });
  };
  const maxPages: number = Math.ceil(maxOnThisSearch / perPage);

  const doRunPermSync = () => {
    setRunPermSync(1);
    ApiService.UsersController.runPermissionsSync((response: IAPIResponse) => {
      if (response.error === null) {
        setRunPermSync(2);
      } else {
        setRunPermSync(0);
        window.document.dispatchEvent(
          new CustomEvent('api-toast-result', {
            detail: {
              text: 'There was an error while starting the permissions sync process. Please, retry in a few minutes...',
              type: 'error',
            },
          })
        );
      }
    });
  };

  const fallBackSave = (userId: string) => {
    reopenUserTarg = userId;
    setReopenUser(userId);
    setShowEdit(false);
    setShowManage('');
    getAllUsers();
  };

  return (
    <div>
      <div className="users-list-page-title">Docware Users</div>
      <Banner
        type="info"
        message={`The synchronization process that makes users' permissions effective will take place every day at 12:00AM and 12:00PM.`}
        enabled={true}
      />
      {loading && (
        <div className="user-detail-spinner-wrap">
          <Spinner label="Loading users..." labelPosition="right" />
        </div>
      )}
      <div style={loading ? {position: 'fixed', top: '-100000px'} : {}}>
        <GenericList
          emitSearchedText={(text: string) => {
            setTextFilter(text);
          }}
          extraHeaderItems={[
            <div style={{width: '20em', marginRight: '1em'}} key={'element_types'}>
              <Dropdown
                options={filter1arg}
                placeholder={'Element Types'}
                selectedKey={filter1}
                onChange={(e, v: any) => {
                  setFilter1(v.key);
                }}
              />
            </div>,
            <div style={{width: '15em', marginRight: '1em'}} key={'professional_areas'}>
              <Dropdown
                options={filter2arg}
                placeholder={'Professional Areas'}
                selectedKey={filter2}
                onChange={(e, v: any) => {
                  setFilter2(v.key);
                }}
              />
            </div>,
            <div style={{marginRight: '1em'}} key={'download_excel'}>
              <LabelButton
                text="Download Excel"
                onClick={() => {
                  let headers: string[] = [
                    'Super Admin',
                    'ID',
                    'User',
                    'Email',
                    'Professional Areas',
                    'Countries (#)',
                    'Libraries',
                    'Element Types',
                    'Creation Date',
                    'Last Login',
                  ];
                  let userDataToDownload: any[] = [];
                  for (let i = 0; i < usersDisplay.length; i++) {
                    let u: any = usersDisplay[i];
                    let entry = [
                      u.isSuperAdmin ? 'Yes' : 'No',
                      u.id,
                      u.joinedName,
                      u.email_,
                      u.professionalAreasParsedStr,
                      u.countriesParsedStr,
                      u.librariesParsedStr,
                      u.elementTypeParsedStr,
                      new Date(u.creationDate).toLocaleDateString(),
                    ];
                    userDataToDownload.push(entry);
                  }

                  ExportListAsExcel('DocwareDrive_AdminUsersExport', headers, userDataToDownload);
                }}
                whiteOutlined
                icon={'ExcelDocument'}
              />
            </div>,
            <div key={'sync_perms'}>
              {loggedUser && loggedUser.isSuperAdmin && (
                <div style={{marginRight: '1em'}}>
                  {runPermSync !== 1 && (
                    <LabelButton
                      orangeSolid
                      disabled={(runPermSync as any) === 2}
                      text="Sync Permissions Now"
                      onClick={doRunPermSync}
                      icon={'Sync'}
                    />
                  )}
                  {runPermSync === 1 && <Spinner label="Running process..." labelPosition="right" />}
                </div>
              )}
            </div>,
          ]}
          onItemInvoked={(item: any) => {
            setEditItem({...item});
            setShowEdit(true);
          }}
          allowFilter={true}
          searchText={'Search users'}
          columns={[
            /*{
              name: 'Enabled',
              dataType: 'string',
              fieldName: '',
              iconName: null,
              size: 's-medium',
              onRender: (item: any) => {
                return (
                  <Toggle
                    onText={'Yes'}
                    offText={'No'}
                    inlineLabel
                    disabled={!loggedUser.isSuperAdmin}
                    checked={item.isEnabled}
                    onChange={(e, b) => {
                      ApiService.UsersController.adminSetEnabledFlag(item.id, b, (response: IAPIResponse) => {
                        if (response.error === null) {
                          getAllUsers(false);
                        }
                      });
                    }}
                  />
                );
              },
            },*/
            {
              name: 'Super Admin',
              dataType: 'string',
              fieldName: 'id',
              iconName: null,
              size: 'medium',
              onRender: (item: any) => {
                return (
                  <Toggle
                    onText={'Yes'}
                    offText={'No'}
                    inlineLabel
                    disabled={!loggedUser.isSuperAdmin}
                    checked={item.isSuperAdmin}
                    onChange={(e: any, b: any) => {
                      ApiService.UsersController.adminSetAdminFlag(item.id, b, (response: IAPIResponse) => {
                        if (response.error === null) {
                          getAllUsers(false);
                        }
                      });
                    }}
                  />
                );
              },
            },
            {
              name: 'ID',
              dataType: 'string',
              fieldName: 'id',
              iconName: null,
              size: 'medium',
            },
            {
              name: 'User',
              dataType: 'string',
              fieldName: 'joinedName',
              iconName: null,
              size: 'medium',
            },
            {
              name: 'Email',
              dataType: 'string',
              fieldName: 'email_',
              iconName: null,
              size: 'medium',
            },
            {
              name: 'Professional Areas',
              dataType: 'string',
              fieldName: 'professionalAreasParsedStr',
              iconName: null,
              size: 'medium',
            },
            {
              name: 'Countries (#)',
              dataType: 'string',
              fieldName: 'countriesParsedStr',
              iconName: null,
              size: 'medium',
            },
            {
              name: 'Libraries',
              dataType: 'string',
              fieldName: 'librariesParsedStr',
              iconName: null,
              size: 'medium',
            },
            {
              name: 'Element Types',
              dataType: 'string',
              fieldName: 'elementTypeParsedStr',
              iconName: null,
              size: 'medium',
            },
            {
              name: 'Creation Date',
              dataType: 'date',
              fieldName: 'creationDate',
              iconName: null,
              size: 'medium',
            }
          ]}
          items={usersDisplay}
        />
        <div className="users-list-page-nav-buttons">
          <LabelButton
            text="Back"
            icon="ChromeBack"
            whiteOutlined
            disabled={page === 0}
            onClick={() => {
              setPage(page - 1);
            }}
          />
          <div className="users-list-page-nav-label">
            <span style={{marginRight: '1em'}}>
              Page {maxPages === 0 ? 0 : page + 1} of {maxPages}, results from {maxPages === 0 ? 0 : 1 + page * perPage} to{' '}
              {maxPages === 0 ? 0 : usersDisplay.length + page * perPage}
            </span>
            <span>
              Per page:
              <select
                style={{marginLeft: '1em'}}
                onChange={(e: any) => {
                  setPage(0);
                  setPerPage(e.target.value);
                }}>
                <option value={10} selected={perPage === 10}>
                  10
                </option>
                <option value={20} selected={perPage === 20}>
                  20
                </option>
                <option value={50} selected={perPage === 50}>
                  50
                </option>
                <option value={100} selected={perPage === 100}>
                  100
                </option>
              </select>
            </span>
          </div>
          <LabelButton
            text="Next"
            icon="ChromeBackMirrored"
            orangeSolid
            disabled={(page + 1) * perPage >= maxOnThisSearch}
            onClick={() => {
              setPage(page + 1);
            }}
          />
        </div>
      </div>
      <ModalDialog
        loadingButtons={savingUserManage}
        modalTitle={'Edit user'}
        onAbort={() => {
          setShowManage('');
        }}
        modalButtons={[
          {
            onClick: () => {
              setShowEdit(true);
              setShowManage('');
            },
            label: 'Back',
          },
          {
            onClick: () => {
              let finalElementTypes: string[] = showManage === 'etypes' ? comboElTypes : editItem.elementTypes;
              let finalProfArea: string[] = showManage === 'pareas' ? comboProfArea : editItem.professionalAreas;

              // create a mock user model to update
              let userToUpdate: any = {
                id: editItem.id,
                firstName: '',
                lastName: '',
                email: '',
                countries: [],
                creationDate: new Date().toISOString(),
                status: 0,
                isSuperAdmin: false,
                professionalAreas: finalProfArea,
                elementTypes: finalElementTypes,
              };

              setSavingUserManage(true);
              ApiService.UsersController.adminManageUser(userToUpdate, (response: IAPIResponse) => {
                setSavingUserManage(false);
                if (response.error === null) {
                  fallBackSave(editItem.id);
                }
              });
            },
            label: 'Save',
          },
        ]}
        enableModal={showManage !== ''}
        modalInnerComponent={
          <div>
            <div className="user-detail-edit-popup-title">{showManage === 'etypes' ? 'Element Types' : 'Professional Areas'}</div>
            <div className="user-detail-edit-popup-sub-title">Current configuration for this user</div>
            {showManage === 'etypes' && (
              <div>{editItem.elementTypes.length > 0 ? ElementTypesIdToNames(editItem.elementTypes, allElementTypes).join(', ') : '-'}</div>
            )}
            {showManage === 'pareas' && (
              <div>
                {editItem.professionalAreas.length > 0
                  ? ProfessionalAreasIdToNames(editItem.professionalAreas, allProfessionalAreas).join(', ')
                  : '-'}
              </div>
            )}
            <div className="user-detail-edit-popup-title" style={{marginTop: '2em'}}>
              Edit manageable items
            </div>
            {comboOptions.length === 0 && showManage !== '' && (
              <div>
                <div className="user-detail-edit-popup-sub-title">
                  This user has no library associated or no option in the user's libraries can be managed from this panel.
                </div>
              </div>
            )}
            {comboOptions.length > 0 && showManage === 'etypes' && (
              <ComboBox
                selectedKey={comboElTypes}
                label="Select options"
                multiSelect
                options={comboOptions}
                onChange={(e, currentValue) => {
                  const newValue = getListOfSelectedKeysMultiSelect(comboElTypes, currentValue);
                  setComboElTypes(newValue.map((x) => x.toString()));
                }}
              />
            )}
            {comboOptions.length > 0 && showManage === 'pareas' && (
              <ComboBox
                selectedKey={comboProfArea}
                label="Select options"
                multiSelect
                options={comboOptions}
                onChange={(e, currentValue) => {
                  const newValue = getListOfSelectedKeysMultiSelect(comboProfArea, currentValue);
                  setComboProfArea(newValue.map((x) => x.toString()));
                }}
              />
            )}
          </div>
        }
      />
      <ModalDialog
        enableModal={reopenUser !== ''}
        modalTitle="Saving changes..."
        loadingButtons={true}
        modalMessage="Please wait until this change takes effect."
      />
      <ModalDialog
        modalTitle={'User detail'}
        modalInnerComponent={<div>{UserInfo()}</div>}
        onAbort={() => {
          setShowEdit(false);
        }}
        modalButtons={[
          {
            onClick: () => {
              setShowEdit(false);
              setShowManage('pareas');
            },
            label: 'Manage Professional Areas',
          },
          {
            onClick: () => {
              setShowEdit(false);
              setShowManage('etypes');
            },
            label: 'Manage Element Types',
          },
          {
            onClick: () => {
              setShowEdit(false);
            },
            label: 'Close',
          },
        ]}
        enableModal={showEdit}
      />
    </div>
  );
};

export default UsersList;
