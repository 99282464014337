import {LabelButton} from '@Eni/docware-fe-master';
import {IDropdown, IDropdownOption, IPersonaProps, Spinner} from '@fluentui/react';
import {createRef, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {IPersonaOptionDto} from '../../Models/IOptionDto';
import {
  BaseLibraryDocType,
  BaseLibraryItem,
  BaseLibraryProfessionalArea,
  IBaseLibraries,
  ILibraryProperties,
  ISendAuthorizationRequest,
  IUser,
} from '../../Models/IUser';
import {getInputInfos} from '../../Reducers/Generic/GenericAction';
import {GlobalState} from '../../Reducers/RootReducer';
import {TSelectUserInfo} from '../../Reducers/User/UserState';
import {IAPIResponse} from '../../Services/AjaxService';
import ApiService from '../../Services/ApiService';
import AppRoutes from '../../Utils/AppRoutes';
import {IntegrationUtils} from '../../Utils/IntegrationUtils';
import { AnchorToApi, AnchorToApiType, getAnchorToApiTypeSpec } from '../AnchorToApi/AnchorToApi';
import {FluentUIDecorator, FluentUIDecoratorTypes} from '../FluentUIDecorator/FluentUIDecorator';
import './UserForm.scss';
import {getUserCountries, optionsToPersonas} from '../../Utils/NewDocumentUtils';
import { EXP_ASSURANCE_NAME } from '../../Constants/Constants';

interface IOption {
  key: string;
  text: string;
}

interface ITag {
  key: string;
  name: string;
}

interface IInitialValues {
  userFirstName: string;
  userLastName: string;
  userID: string;
  userEmail: string;
  email: string;
  library: string;
  professionalAreas: string[];
  countries: ITag[];
  notes: string;
  documentTypes: string[];
  readWrite: number;
  documentSubTypes: string[];
}

interface IOptionField {
  key: string;
  text: string;
}

export const UserForm = () => {
  const [libraries, setLibraries] = useState<IBaseLibraries[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const [reloadMsItems, setReloadMsItems] = useState<string[]>([]);

  const doReloadMsItems = (items: string[]) => {
    setReloadMsItems(items);
    setTimeout(() => {
      setReloadMsItems([]);
    }, 200);
  };

  const [professionalAreaOptions, setProfessionalAreaOptions] = useState<IOptionField[]>([]);
  const [documentTypeOptions, setDocumentTypeOptions] = useState<IOptionField[]>([]);
  const [documentSubTypeOptions, setDocumentSubTypeOptions] = useState<IOptionField[]>([]);
  const [selectedReferent, setSelectedReferent] = useState<IPersonaProps[]>(undefined);
  const [seenUsers, setSeenUsers] = useState<any[]>([]);

  const currentUser: IUser = useSelector((state: GlobalState) => state.user.currentUser);
  const userInfo: TSelectUserInfo = useSelector((state: GlobalState) => state.user.selectUserInfo);
  const ProfAreaDropDownRef = createRef<IDropdown>();
  const DocTypeDropDownRef = createRef<IDropdown>();
  const DocSubTypeDropDownRef = createRef<IDropdown>();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formState, setFormState] = useState<IInitialValues>({
    userFirstName: '',
    userLastName: '',
    userID: '',
    userEmail: '',
    email: undefined,
    library: '',
    professionalAreas: [],
    countries: undefined,
    notes: '',
    documentTypes: [],
    readWrite: undefined,
    documentSubTypes: [],
  });

  const getDocumentTypesOptions = () => {
    let areas = formState.professionalAreas;
    let docTypeOptions: IOption[] = [];
    for (let i = 0; i < libraries.length; i++) {
      let lib: IBaseLibraries = libraries[i];
      for (let j = 0; j < lib.professionalAreas.length; j++) {
        let area: BaseLibraryProfessionalArea = lib.professionalAreas[j];
        if (areas.includes(area.id)) {
          for (let k = 0; k < area.documentTypes.length; k++) {
            let docType: BaseLibraryItem = area.documentTypes[k];
            if (docTypeOptions.filter((x: IOption) => x.key === docType.id).length === 0) {
              docTypeOptions.push({key: docType.id, text: docType.name});
            }
          }
        }
      }
    }
    return docTypeOptions;
  };

  const getDocumentSubTypesOptions = () => {
    let areas = formState.professionalAreas;
    let docTypes = formState.documentTypes;
    let docSubTypeOptions: IOption[] = [];
    for (let i = 0; i < libraries.length; i++) {
      let lib: IBaseLibraries = libraries[i];
      for (let j = 0; j < lib.professionalAreas.length; j++) {
        let area: BaseLibraryProfessionalArea = lib.professionalAreas[j];
        if (areas.includes(area.id)) {
          for (let k = 0; k < area.documentTypes.length; k++) {
            let docType: BaseLibraryDocType = area.documentTypes[k];
            if (docTypes.includes(docType.id)) {
              for (let h = 0; h < docType.subTypes.length; h++) {
                let subType: BaseLibraryItem = docType.subTypes[h];
                if (docSubTypeOptions.filter((x: IOption) => x.key === subType.id).length === 0) {
                  docSubTypeOptions.push({key: subType.id, text: subType.name});
                }
              }
            }
          }
        }
      }
    }
    return docSubTypeOptions;
  };

  const submitForm = () => {
    const sendRequest = {
      professionalAreasIds: formState.professionalAreas,
      supervisorEmail: formState.email,
      countries: formState.countries.map((x: ITag) => {
        return x.name;
      }),
      documentTypesIds: formState.documentTypes,
      documentSubTypesIds: formState.documentSubTypes,
      permissionType: formState.readWrite,
      note: formState.notes,
      libraryId: formState.library,
    } as ISendAuthorizationRequest;

    setSending(true);
    ApiService.UsersController.sendAuthorizationRequest(sendRequest, (response: IAPIResponse) => {
      setSending(false);
      if (response.error === null) {
        localStorage.setItem('tell-form-was-ok', '');
        navigate(AppRoutes.USER_INFO);
      } else {
        window.document.dispatchEvent(
          new CustomEvent('api-toast-result', {
            detail: {
              text: 'There was an error when submitting the form. Please, try again later.',
              type: 'error',
            },
          })
        );
      }
    });
  };

  const updateFormValue = (key: string, value: any) => {
    let formState_: IInitialValues = {...formState};
    formState_[key] = value;
    setFormState(formState_);
  };

  useEffect(() => {
    setLoading(true);
    ApiService.UsersController.getLibraries((response: IAPIResponse) => {
      if (response.error === null) {
        setLibraries(response.payload);
      }
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (currentUser && libraries.length > 0) {
      let areaId = bindLibrary(userInfo.libraryId ?? '');
      let formState_: IInitialValues = {...formState};
      formState_.userFirstName = currentUser.firstName;
      formState_.userLastName = currentUser.lastName;
      formState_.userEmail = currentUser.email;
      formState_.userID = currentUser.id;
      formState_.library = userInfo.libraryId ?? '';
      formState_.professionalAreas = [areaId];
      setFormState(formState_);
    }
  }, [libraries]);

  const getCountriesForSelectedLibrary = (libraryId: string) => {
    if (libraryId !== '') {
      let matchLib: IBaseLibraries[] = libraries.filter((x: IBaseLibraries) => x.id === libraryId);
      if (matchLib.length > 0) {
        let targetLib: IBaseLibraries = matchLib[0];
        return targetLib.countries.map((x: ILibraryProperties) => {
          return {
            id: x.id,
            code: x.id,
            name: x.name,
            library: libraryId,
          };
        });
      }
    }
    return [];
  };

  const bindLibrary = (libraryId: string) => {
    let targetAreaId = '';
    if (libraryId !== '') {
      let matchLib: IBaseLibraries[] = libraries.filter((x: IBaseLibraries) => x.id === libraryId);
      if (matchLib.length > 0) {
        let targetLib: IBaseLibraries = matchLib[0];
        if (targetLib.professionalAreas.length === 1) {
          targetAreaId = targetLib.professionalAreas[0].id;
        }
        setProfessionalAreaOptions(
          targetLib.professionalAreas.map((x: ILibraryProperties) => {
            return {key: x.id, text: x.name};
          })
        );
      }
    }
    return targetAreaId;
  };

  useEffect(() => {
    let areaBindId = bindLibrary(formState.library);
    setTimeout(() => {
      if (areaBindId !== '') {
        updateFormValue('professionalAreas', [areaBindId]);
      } else {
        updateFormValue('professionalAreas', []);
      }
    }, 200);
  }, [formState.library]);

  useEffect(() => {
    setDocumentTypeOptions(getDocumentTypesOptions());
    doReloadMsItems(['docType', 'docSubType']);
  }, [formState.professionalAreas]);

  const updateSubTypeSelection = (newOptions: {key: string; text: string}[]) => {
    let optionsKeys = newOptions.map((x: {key: string; text: string}, i: number) => {
      return x.key;
    });
    let currSubTypes = formState.documentSubTypes;
    let filtered = currSubTypes.filter((x: string) => optionsKeys.indexOf(x) !== -1);
    if (filtered.join('') !== currSubTypes.join('')) {
      setTimeout(() => {
        updateFormValue('documentSubTypes', filtered);
        doReloadMsItems(['docSubType']);
      }, 200);
    }
  };

  useEffect(() => {
    let newOptions = getDocumentSubTypesOptions();
    setDocumentSubTypeOptions(newOptions);
    if (formState.professionalAreas.length > 0 && formState.library !== '') {
      updateSubTypeSelection(newOptions);
    }
  }, [formState.documentTypes]);

  useEffect(() => {
    let formState_: IInitialValues = {...formState};
    formState_.countries = undefined;
    formState_.documentTypes = [];
    formState_.documentSubTypes = [];
    setFormState(formState_);
  }, [formState.library, formState.professionalAreas]);

  const invalidField = (field: any) => {
    if (field === '' || field === null || field === undefined) {
      return true;
    }
    if (Array.isArray(field) && field.length === 0) {
      return true;
    }

    return false;
  };

  const formIsValid = () => {
    let state: IInitialValues = {...formState};
    delete state['notes'];

    if (state.readWrite === 0) {
      delete state.documentTypes;
      delete state.documentSubTypes;
    }

    let extactedValues: any[] = Object.values(state);
    console.log(state);
    console.log('extracted values');
    console.log(extactedValues);
    // form is valid if there are 0 required fields who are missing
    return extactedValues.filter((x: any) => invalidField(x)).length === 0;
  };

  const userEmailById = (id: string) => {
    let match: any[] = seenUsers.filter((x) => x.id === id);
    if (match.length > 0) {
      return match[0].email;
    }
    return '';
  };

  const addSeenUsers = (results: any[]) => {
    let usr: any[] = [...seenUsers];
    for (let i = 0; i < results.length; i++) {
      if (usr.filter((x: any) => x.id === results[i].id).length === 0) {
        usr.push(results[i]);
      }
    }
    setSeenUsers(usr);
  };

  return (
    <form className="user-form-wrapper">
      <h1>Authorization Request</h1>
      {loading && (
        <div className="user-form-spinner-wrap">
          <Spinner label="Loading..." />
        </div>
      )}
      {!loading && (
        <div>
          <div className="user-form-section">
            <FluentUIDecorator
              fluentComponent={FluentUIDecoratorTypes.TextField({
                placeholder: 'UserID',
                value: formState.userID,
                disabled: true,
              })}
              label={'UserID'}
              info={getInputInfos('permission-request', 'user-id')}
              required
            />
            <FluentUIDecorator
              fluentComponent={FluentUIDecoratorTypes.TextField({
                value: formState.userFirstName,
                disabled: true,
              })}
              label={'First Name'}
              info={getInputInfos('permission-request', 'first-name')}
              required
            />
            <FluentUIDecorator
              fluentComponent={FluentUIDecoratorTypes.TextField({
                placeholder: 'Insert LastName',
                value: formState.userLastName,
                disabled: true,
              })}
              label={'Last Name'}
              info={getInputInfos('permission-request', 'last-name')}
              required
            />
            <FluentUIDecorator
              fluentComponent={FluentUIDecoratorTypes.TextField({
                value: formState.userEmail,
                disabled: true,
              })}
              label={'User Email'}
              info={getInputInfos('permission-request', 'user-email')}
              required
            />
          </div>

          <div className="user-form-section">
            <FluentUIDecorator
              denyComponentHardReset
              fluentComponent={FluentUIDecoratorTypes.PeoplePicker({
                selectedUsers: selectedReferent,
                onFilterChanged: async (
                  filterText: string,
                  currentPersonas: IPersonaProps[] | undefined,
                  limitResults?: number | undefined
                ) => {
                  if (filterText && filterText.length > 3) {
                    let results: any[] = await IntegrationUtils.resolvePeoplePickerVoicesNoCastAuthorizationRequest(
                      filterText,
                      currentPersonas
                    );
                    addSeenUsers(results);
                    let users: IPersonaOptionDto[] = results
                      .filter((x) => x.email && x.email !== '')
                      .map((u) => {
                        return {
                          id: u.id,
                          name: `${u.firstName} ${u.lastName}`,
                          secondaryText: u.email,
                        };
                      }) as IPersonaOptionDto[];

                    return optionsToPersonas(users);
                  } else {
                    return [];
                  }
                },
                onPeopleChanged: (p: IPersonaProps[]) => {
                  if (p && p.length > 0) {
                    let lastU: IPersonaProps = p[p.length - 1];
                    setSelectedReferent([lastU]);
                    let email: string = userEmailById(lastU.key.toString());
                    updateFormValue('email', email);
                  } else {
                    setSelectedReferent([]);
                    updateFormValue('email', null);
                  }
                },
              })}
              label={'Referent Email'}
              info={getInputInfos('permission-request', 'referent-email')}
              required
            />
            <FluentUIDecorator
              fluentComponent={FluentUIDecoratorTypes.Dropdown({
                selectedKey: formState.library,
                options: libraries
                  .filter((l: IBaseLibraries) => l.showAskPermissionForButton)
                  .map((x: IBaseLibraries) => {
                    return {key: x.id, text: x.name};
                  }),
                placeholder: 'Select Library',
                onChange: (e, o) => {
                  updateFormValue('library', o.key);
                },
              })}
              label={'Library'}
              info={getInputInfos('permission-request', 'library')}
              required
            />

            <FluentUIDecorator
              fluentComponent={FluentUIDecoratorTypes.Dropdown({
                options: [{key: 'All', text: 'All'}].concat(professionalAreaOptions),
                placeholder: 'Select Document Sub-Type',
                componentRef: ProfAreaDropDownRef,
                defaultSelectedKeys: formState.professionalAreas,
                multiSelect: true,
                onChange: (e, o: IDropdownOption) => {
                  if (o.key === 'All') {
                    (window as any)['professionalAreasWasAll'] = true;
                  } else {
                    (window as any)['professionalAreasWasAll'] = false;
                  }
                  setTimeout(() => {
                    if (
                      (window as any)['professionalAreasWasAll'] &&
                      ProfAreaDropDownRef.current.selectedOptions.filter((x) => x.key === 'All').length > 0
                    ) {
                      updateFormValue(
                        'professionalAreas',
                        professionalAreaOptions.map((x: any) => {
                          return x.key;
                        })
                      );
                    } else if (
                      (window as any)['professionalAreasWasAll'] &&
                      ProfAreaDropDownRef.current.selectedOptions.filter((x) => x.key === 'All').length === 0
                    ) {
                      updateFormValue('professionalAreas', []);
                    } else {
                      updateFormValue(
                        'professionalAreas',
                        ProfAreaDropDownRef.current.selectedOptions
                          .filter((x) => x.key !== 'All')
                          .map((x: any) => {
                            return x.key;
                          })
                      );
                    }
                  }, 300);
                },
              })}
              denyComponentHardReset
              label={'Professional Areas'}
              info={getInputInfos('permission-request', 'professional-areas')}
              required
            />
          </div>
          <div className="user-form-section">
            <FluentUIDecorator
              fluentComponent={FluentUIDecoratorTypes.TagPicker({
                disabled: !Boolean(formState.professionalAreas.length > 0),
                inputProps: {placeholder: 'Select countries'},
                selectedItems: formState.countries,
                onResolveSuggestions: (f, s) =>
                  getUserCountries(f, s, getCountriesForSelectedLibrary(formState.library), formState.library ? [formState.library] : []), //IntegrationUtils.resolveIntegrationTags(f, s, "xware_countries"),     getUserCountries(f, s, permissions.countries)
                onChange: (t) => {
                  updateFormValue('countries', t);
                },
              })}
              label={'Countries'}
              info={getInputInfos('permission-request', 'countries')}
              required
            />

            <FluentUIDecorator
              fluentComponent={FluentUIDecoratorTypes.Dropdown({
                options: [
                  {key: 0, text: 'Read Only', order: 0} as IDropdownOption,
                  {key: 1, text: 'Read & Write', order: 1} as IDropdownOption,
                ],
                placeholder: 'Select a permission type',
                selectedKey: formState.readWrite,
                onChange: (e, o) => {
                  let toggle = o.key;
                  let f = {...formState};
                  f.readWrite = +toggle;
                  if (toggle === 0) {
                    f.documentTypes = [];
                    f.documentSubTypes = [];
                  }
                  setFormState(f);
                },
                disabled: !Boolean(formState.library !== ''),
              })}
              denyComponentHardReset
              label={'Which permissions do you want to have?'}
              info={getInputInfos('permission-request', 'permission-type')}
              required
            />
          </div>

          {formState.readWrite === 1 && (
            <div className="user-form-section">
              <FluentUIDecorator
                fluentComponent={FluentUIDecoratorTypes.Dropdown({
                  options: [{key: 'All', text: 'All'}].concat(documentTypeOptions),
                  placeholder: 'Select Document Type',
                  componentRef: DocTypeDropDownRef,
                  multiSelect: true,
                  defaultSelectedKeys: formState.documentTypes,
                  onChange: (e, o: IDropdownOption) => {
                    if (o.key === 'All') {
                      (window as any)['documentTypesWasAll'] = true;
                    } else {
                      (window as any)['documentTypesWasAll'] = false;
                    }
                    setTimeout(() => {
                      if (
                        (window as any)['documentTypesWasAll'] &&
                        DocTypeDropDownRef.current.selectedOptions.filter((x) => x.key === 'All').length > 0
                      ) {
                        updateFormValue(
                          'documentTypes',
                          documentTypeOptions.map((x: any) => {
                            return x.key;
                          })
                        );
                      } else if (
                        (window as any)['documentTypesWasAll'] &&
                        DocTypeDropDownRef.current.selectedOptions.filter((x) => x.key === 'All').length === 0
                      ) {
                        updateFormValue('documentTypes', []);
                      } else {
                        updateFormValue(
                          'documentTypes',
                          DocTypeDropDownRef.current.selectedOptions
                            .filter((x) => x.key !== 'All')
                            .map((x: any) => {
                              return x.key;
                            })
                        );
                      }
                    }, 300);
                  },
                  disabled: !Boolean(formState.professionalAreas.length > 0),
                })}
                denyComponentHardReset={reloadMsItems.indexOf('docType') === -1}
                label={<AnchorToApi file={getAnchorToApiTypeSpec(AnchorToApiType.pdfTypeSubtype) } />}
                info={getInputInfos('permission-request', 'document-type')}
                required
              />
              <FluentUIDecorator
                fluentComponent={FluentUIDecoratorTypes.Dropdown({
                  options: [{key: 'All', text: 'All'}].concat(documentSubTypeOptions),
                  placeholder: 'Select Document Sub-Type',
                  componentRef: DocSubTypeDropDownRef,
                  defaultSelectedKeys: formState.documentSubTypes,
                  multiSelect: true,
                  onChange: (e, o: IDropdownOption) => {
                    if (o.key === 'All') {
                      (window as any)['documentSubTypesWasAll'] = true;
                    } else {
                      (window as any)['documentSubTypesWasAll'] = false;
                    }
                    setTimeout(() => {
                      if (
                        (window as any)['documentSubTypesWasAll'] &&
                        DocSubTypeDropDownRef.current.selectedOptions.filter((x) => x.key === 'All').length > 0
                      ) {
                        updateFormValue(
                          'documentSubTypes',
                          documentSubTypeOptions.map((x: any) => {
                            return x.key;
                          })
                        );
                      } else if (
                        (window as any)['documentSubTypesWasAll'] &&
                        DocSubTypeDropDownRef.current.selectedOptions.filter((x) => x.key === 'All').length === 0
                      ) {
                        updateFormValue('documentSubTypes', []);
                      } else {
                        updateFormValue(
                          'documentSubTypes',
                          DocSubTypeDropDownRef.current.selectedOptions
                            .filter((x) => x.key !== 'All')
                            .map((x: any) => {
                              return x.key;
                            })
                        );
                      }
                    }, 300);
                  },

                  disabled: !Boolean(formState.professionalAreas.length > 0),
                })}
                label={'Document Sub-Type'}
                info={getInputInfos('permission-request', 'document-sub-type')}
                denyComponentHardReset={reloadMsItems.indexOf('docSubType') === -1}
                required
              />
            </div>
          )}

          <hr style={{marginTop: '1em'}} />

          <FluentUIDecorator
            fluentComponent={FluentUIDecoratorTypes.TextField({
              value: formState.notes,
              onChange: (t, value) => {
                updateFormValue('notes', value);
              },
              multiline: true,
              maxLength: 600,
              rows: 10,
            })}
            label={'Notes'}
            info={getInputInfos('permission-request', 'notes')}
          />
          <div className="user-form-send">
            {!sending && (
              <LabelButton
                icon="Send"
                orangeSolid
                onClick={() => {
                  submitForm();
                }}
                text="Send"
                disabled={!formIsValid()}
              />
            )}
            {sending && <Spinner label="Sending request..." labelPosition="right" />}
          </div>
        </div>
      )}
    </form>
  );
};
