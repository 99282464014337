import {DocumentManagementToolbar, IDocumentManagementToolbarLayout, ModalDialog} from '@Eni/docware-fe-master';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {FileInfo} from '../../Models/FileUtils';
import {IDocumentItem, INewDocument} from '../../Models/IDocument';
import {IExportStatus} from '../../Models/ILibrary';
import {IPermissionByData} from '../../Models/IUser';
import {AdminAction} from '../../Reducers/Admin/AdminActions';
import {DocumentListAction} from '../../Reducers/DocumentSearch/DocumentSearchActions';
import {GenericActions, getInputInfos} from '../../Reducers/Generic/GenericAction';
import {MassiveImportAction} from '../../Reducers/MassiveImport/MassiveImportActions';
import {GlobalState} from '../../Reducers/RootReducer';
import store from '../../Reducers/Store';
import {IAPIResponse} from '../../Services/AjaxService';
import ApiService from '../../Services/ApiService';
import AppRoutes, {concatUrl} from '../../Utils/AppRoutes';
import URIformatter from '../../Utils/URIformatter';
import {getDocumentsToDelete} from '../ContextMenu/ContextMenu';
import DownloadDocumentModal from '../DownloadDocumentModal/DownloadDocumentModal';
import EmailPicker from '../EmailPicker/EmailPicker';
import {FluentUIDecorator, FluentUIDecoratorTypes} from '../FluentUIDecorator/FluentUIDecorator';
import {MoveToTrashSelectedDocuments} from '../MoveToTrashSelectedDocuments/MoveToTrashSelectedDocuments';
import PivotFluentWrap from '../PivotFluentWrap/PivotFluentWrap';
import './DriveDocumentManagementToolbar.scss';
import { useNavigateToSearchAndReset } from '../../Hooks/useNavigateToSearchAndReset';

const sortByItems = [
  {
    key: 'score',
    text: 'Relevance',
    onClick: (k: any, v: any) => {
      store.dispatch(GenericActions.setSortSearchBy(v.key));
    },
  },
  {
    key: 'documentName',
    text: 'Name',
    onClick: (k: any, v: any) => {
      store.dispatch(GenericActions.setSortSearchBy(v.key));
    },
  },
  {
    key: 'area',
    text: 'Area',
    onClick: (k: any, v: any) => {
      store.dispatch(GenericActions.setSortSearchBy(v.key));
    },
  },
  {
    key: 'documentDate',
    text: 'Document Date',
    onClick: (k: any, v: any) => {
      store.dispatch(GenericActions.setSortSearchBy(v.key));
    },
  },
  {
    key: 'updateDate',
    text: 'Last Modified Date',
    onClick: (k: any, v: any) => {
      store.dispatch(GenericActions.setSortSearchBy(v.key));
    },
  },
  {
    key: 'fileSize',
    text: 'File Size',
    onClick: (k: any, v: any) => {
      store.dispatch(GenericActions.setSortSearchBy(v.key));
    },
  },
];

const sortDirectionItems = [
  {
    key: true,
    text: 'Descending',
    onClick: (k: any, v: any) => {
      store.dispatch(GenericActions.setSortSearchOrder(v.key));
    },
  },
  {
    key: false,
    text: 'Ascending',
    onClick: (k: any, v: any) => {
      store.dispatch(GenericActions.setSortSearchOrder(v.key));
    },
  },
];

export interface IDriveDocumentManagementToolbarProps {
  type?: string;
  fileType?: 'main' | 'signature' | 'attachment';
  customLayout?: IDocumentManagementToolbarLayout;
  onExitAction?: () => void;
  currentDocument?: INewDocument;
}

export const DriveDocumentManagementToolbar = (props: IDriveDocumentManagementToolbarProps) => {
  const navigate = useNavigate();
  const navigateToSearchAndReset = useNavigateToSearchAndReset();
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const location = useLocation();
  const [openSearch, setOpenSearch] = useState<boolean>(true);
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const [showEditOnModal, setShowEditOnModal] = useState<boolean>(false);
  const [showEditOnModalLoading, setShowEditOnModalLoading] = useState<boolean>(false);
  const [documentIsFavorite, setDocumentIsFavorite] = useState<boolean>(false);
  const loggedUser = useSelector((state: GlobalState) => state.user.currentUser);
  const [documentId, setDocumentId] = useState<string | undefined>(undefined);

  const [showRevertModal, setShowRevertModal] = useState<boolean>(false);

  const [disableFavoriteButton, setDisableFavoriteButtonInner] = useState<boolean>(false);
  const permissions = useSelector((state: GlobalState) => state.user.permissionsByActions);
  const [currentDocumentPermissions, setCurrentDocumentPermissions] = useState<IPermissionByData | null>(null);
  const [currentMultiDocumentsPermissions, setCurrentMultiDocumentsPermissions] = useState<IPermissionByData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const generic = useSelector((state: GlobalState) => state.generic);

  const [emailsList, setEmailsList] = useState<string[]>([]);
  const [sharing, setSharing] = useState<boolean>(false);
  const [showSearchExport, setShowSearchExport] = useState<boolean>(false);
  const [numberDocumentsToExport, setNumberDocumentsToExport] = useState<number>(100);
  const [searchExportLoading, setSearchExportLoading] = useState<boolean>(false);
  const genericState = useSelector((state: GlobalState) => state.generic);
  const massiveImportState = useSelector((state: GlobalState) => state.massiveImport);
  const newsState = useSelector((state: GlobalState) => state.admin.newsTab);
  const adminReduxState = useSelector((state: GlobalState) => state.admin);
  const documentSearch = useSelector((state: GlobalState) => state.documentSearch);
  /******************* Begin Move to trash variables **********************************/
  const [showTrashModal, setShowTrashModal] = useState<boolean>(false);
  const [deleteLevel, setDeleteLevel] = useState<number>(-1);
  const [documentIdsToDelete, setDocumentIdsToDelete] = useState<string[]>([]);
  const [checkDocumentVersionInfo, setCheckDocumentVersionInfo] = useState<boolean>();
  /******************* End Move to trash variables ************************************/
  /******************* Begin Download Document Modal variables **********************************/
  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
  const [documentIDsToDownload, setDocumentIDsToDownload] = useState<string[]>([]);
  /******************* End Download Document Modal variables ************************************/

  const setDisableFavoriteButton = (value: boolean) => {
    if (value) {
      setDisableFavoriteButtonInner(true);
    } else {
      try {
        setTimeout(() => {
          setDisableFavoriteButtonInner(false);
        }, 2000);
      } catch (e) {}
    }
  };

  const loadMultiDocumentsPermissions = async () => {
    setLoading(true);
    let perms: IPermissionByData[] = [];
    for (let i = 0; i < documentSearch.activeDocuments.length; i++) {
      let response: IAPIResponse = await ApiService.DocumentController.getUserPermissionsOnDocument(
        documentSearch.activeDocuments[i].documentId
      );
      if (response.error === null) {
        let perm: IPermissionByData = response.payload;
        perm.documentId = documentSearch.activeDocuments[i].documentId;
        perms.push(perm);
      }
    }
    setCurrentMultiDocumentsPermissions(perms);
    setLoading(false);
  };

  useEffect(() => {
    if (documentSearch.activeDocuments.length > 0) {
      loadMultiDocumentsPermissions();
    } else {
      let docId = params.get('documentid') ?? documentSearch.activeDocument?.documentId;
      if (loggedUser && docId) {
        setCurrentDocumentPermissions(null);
        setLoading(true);
        ApiService.DocumentController.getUserPermissionsOnDocument(docId, (response: IAPIResponse) => {
          if (response.error === null) {
            setCurrentDocumentPermissions(response.payload);
          }
          setLoading(false);
        });

        ApiService.DocumentController.checkDocumentIsFavorite(loggedUser.id, docId, (response: IAPIResponse) => {
          if (response.error == null) {
            setDocumentIsFavorite(response.payload);
          }
        });
      }
      setDocumentId(docId);
    }
  }, [genericState.reloadRequest, props.type, props.customLayout, documentSearch.activeDocument, documentSearch.activeDocuments]);

  const openAdvSearch = () => {
    setOpenSearch(true);
  };

  const closeAdvSearch = () => {
    setOpenSearch(false);
  };

  useEffect(() => {
    window.document.addEventListener('adv-search-filter-open', openAdvSearch);
    window.document.addEventListener('adv-search-filter-close', closeAdvSearch);

    return () => {
      window.document.removeEventListener('adv-search-filter-open', openAdvSearch);
      window.document.removeEventListener('adv-search-filter-close', closeAdvSearch);
    };
  }, []);

  const tabs = [
    {
      label: 'All',
      onClick: () => {
        navigateToSearchAndReset(concatUrl(AppRoutes.DOCUMENT_SEARCH, AppRoutes.DOCUMENT_SEARCH_ALL) + window.location.search);
      },
    },
    {
      label: 'Images',
      onClick: () => {
        navigateToSearchAndReset(concatUrl(AppRoutes.DOCUMENT_SEARCH, AppRoutes.DOCUMENT_SEARCH_IMAGES) + window.location.search);
      },
    },
    {
      label: 'Map',
      onClick: () => {
        navigateToSearchAndReset(concatUrl(AppRoutes.DOCUMENT_SEARCH, AppRoutes.DOCUMENT_SEARCH_MAP) + window.location.search);
      },
    },
  ];

  const knownMenuLayouts = [
    //** layout for when we are in the search page and no document is selected */
    {
      name: 'actions-on-search-page',
      content: {
        leftButtons: [],
        specials: [
          {
            item: <div className="management-toolbar-search-title">Search</div>,
          },
          {
            item: (
              <PivotFluentWrap
                onInitProcedure={() => {
                  if (window.location.href.indexOf(AppRoutes.DOCUMENT_SEARCH_IMAGES) !== -1) {
                    return '1';
                  }
                  if (window.location.href.indexOf(AppRoutes.DOCUMENT_SEARCH_ATTACHMENTS) !== -1) {
                    return '2';
                  }
                  return '0';
                }}
                tabs={tabs}
              />
            ),
          },
        ],
        rightButtons: [
          {
            text: 'Download all',
            iconName: 'Download',
            disabled: documentSearch.activeSearchResults.length === 0,
            onClick: () => {
              setShowDownloadModal(true);
              setDocumentIDsToDownload(documentSearch.activeSearchResults.map((x) => x.documentId));
            },
          },
          {
            text: 'Export Results',
            iconName: 'Download',
            onClick: () => {
              setShowSearchExport(true);
            },
          },
          {
            text: 'Show Filters',
            iconName: 'Filter',
            disabled: openSearch,
            onClick: () => {
              window.document.dispatchEvent(new Event('adv-search-filter-open'));
            },
          },
          {
            text: 'Sort By',
            iconName: 'Sort',
            menuProps: {
              items: sortByItems,
            },
          },
          {
            text: 'Sort Direction',
            iconName: 'SortLines',
            menuProps: {
              items: sortDirectionItems,
            },
          },
        ],
      },
    },
    //** layout for when a document is visible on a detail page. This layout assumes the document cannot be opened more */
    {
      name: 'actions-on-open-document',
      content: {
        leftButtons: [
          {
            text: 'Edit Attributes',
            iconName: 'Edit',
            disabled: props.currentDocument?.redirectEditUrl
              ? true
              : currentDocumentPermissions
              ? !currentDocumentPermissions.canEdit
              : true,
            onClick: () => {
              if (documentId) {
                navigate(AppRoutes.DOCUMENT_CREATION_ROUTE + '?documentid=' + documentId.toString() + '&mode=edit');
              }
            },
          },
          {
            text: `Edit On ${props.currentDocument?.sourceAppName}`,
            iconName: 'Edit',
            disabled: props.currentDocument?.redirectEditUrl
              ? currentDocumentPermissions
                ? !currentDocumentPermissions.canEdit
                : true
              : true,
            onClick: () => {
              setShowEditOnModal(true);
            },
          },
          {
            text: 'Revert to Draft',
            iconName: 'ReturnKey',
            disabled: currentDocumentPermissions ? !currentDocumentPermissions.canRevert : true,
            onClick: () => {
              if (documentId) {
                setShowRevertModal(true);
              }
            },
          },
          {
            text: 'New Version',
            iconName: 'VersionControlPush',
            disabled: currentDocumentPermissions ? !currentDocumentPermissions.canCreateNewVersion : true,
            onClick: () => {
              if (documentId) {
                ApiService.DocumentController.generateDocumentSnapshot(documentId, (response: IAPIResponse) => {
                  if (response.error === null) {
                    localStorage.removeItem('new-document-lock-url');
                    navigate(
                      AppRoutes.DOCUMENT_CREATION_ROUTE + '?documentid=' + response.payload + '&mode=newversion&previousId=' + documentId
                    );
                  } else {
                    if (response.raw.status === 404) {
                      window.document.dispatchEvent(
                        new CustomEvent('api-toast-result', {
                          detail: {
                            text: 'Cannot create New Version: this document already has a new version.',
                            type: 'error',
                          },
                        })
                      );
                    }
                  }
                });
              }
            },
          },
          {
            text: 'Move to Trash',
            iconName: 'RecycleBin',
            disabled: currentDocumentPermissions ? !currentDocumentPermissions.canDelete : true,
            onClick: () => {
              if (documentId) {
                setDeleteLevel(2);
                //loadDocumentDeletingDocumentVersionInfo([documentId]);
                setCheckDocumentVersionInfo(true);
                setDocumentIdsToDelete([documentId]);
                setShowTrashModal(true);
              }
            },
          },
          {
            text: 'Download',
            iconName: 'Download',
            //disabled: !documentSearch.activeDocument,
            onClick: () => {
              setShowDownloadModal(true);
              setDocumentIDsToDownload([props.currentDocument.documentId]);
            }
          },
          {
            text: 'Share',
            iconName: 'Share',
            onClick: () => {
              setShowShareModal(true);
            },
          },
          {
            text: documentIsFavorite ? 'Remove from Favourites' : 'Add to Favourites',
            iconName: 'SingleBookmark',
            inactive: disableFavoriteButton,
            onClick: () => {
              if (loggedUser && documentId && !disableFavoriteButton) {
                setDisableFavoriteButton(true);
                if (documentIsFavorite) {
                  ApiService.DocumentController.deleteFavoriteDocument([documentId.toString()], (res: IAPIResponse) => {
                    /** trick to force the Drive page reload */
                    dispatch(DocumentListAction.setActiveDocument(null));
                    dispatch(DocumentListAction.setActiveDocuments([]));
                    store.dispatch(GenericActions.reloadRequest());
                    setDisableFavoriteButton(false);
                  });
                } else {
                  ApiService.DocumentController.addFavoriteDocument([documentId.toString()], (res: IAPIResponse) => {
                    /** trick to force the Drive page reload */
                    dispatch(DocumentListAction.setActiveDocument(null));
                    dispatch(DocumentListAction.setActiveDocuments([]));
                    store.dispatch(GenericActions.reloadRequest());
                    setDisableFavoriteButton(false);
                  });
                }
              }
            },
          },
        ],
        rightButtons: [],
      },
    },

    //** layout for when a document is visible on a detail page. This layout assumes the document cannot be opened more and is draft*/
    {
      name: 'actions-on-open-draft-document',
      content: {
        leftButtons: [
          {
            text: 'Edit Attributes',
            iconName: 'Edit',
            disabled: props.currentDocument?.redirectEditUrl
              ? true
              : currentDocumentPermissions
              ? !currentDocumentPermissions.canEdit
              : true,
            onClick: () => {
              if (documentId) {
                navigate(AppRoutes.DOCUMENT_CREATION_ROUTE + '?documentid=' + documentId.toString() + '&mode=edit');
              }
            },
          },
          {
            text: `Edit On ${props.currentDocument?.sourceAppName}`,
            iconName: 'Edit',
            disabled: props.currentDocument?.redirectEditUrl
              ? currentDocumentPermissions
                ? !currentDocumentPermissions.canEdit
                : true
              : true,
            onClick: () => {
              setShowEditOnModal(true);
            },
          },
          {
            text: 'Move to Trash',
            iconName: 'RecycleBin',
            disabled: currentDocumentPermissions ? !currentDocumentPermissions.canDelete : true,
            onClick: () => {
              if (documentId) {
                setDeleteLevel(2);
                //loadDocumentDeletingDocumentVersionInfo([documentId]);
                setCheckDocumentVersionInfo(true);
                setDocumentIdsToDelete([documentId]);
                setShowTrashModal(true);
              }
            },
          },
          {
            text: 'Share',
            iconName: 'Share',
            onClick: () => {
              setShowShareModal(true);
            },
          },
          {
            text: documentIsFavorite ? 'Remove from Favourites' : 'Add to Favourites',
            iconName: 'SingleBookmark',
            inactive: disableFavoriteButton,
            onClick: () => {
              if (loggedUser && documentId && !disableFavoriteButton) {
                setDisableFavoriteButton(true);
                if (documentIsFavorite) {
                  ApiService.DocumentController.deleteFavoriteDocument([documentId.toString()], (res: IAPIResponse) => {
                    /** trick to force the Drive page reload */
                    dispatch(DocumentListAction.setActiveDocument(null));
                    dispatch(DocumentListAction.setActiveDocuments([]));
                    store.dispatch(GenericActions.reloadRequest());
                    setDisableFavoriteButton(false);
                  });
                } else {
                  ApiService.DocumentController.addFavoriteDocument([documentId.toString()], (res: IAPIResponse) => {
                    /** trick to force the Drive page reload */
                    dispatch(DocumentListAction.setActiveDocument(null));
                    dispatch(DocumentListAction.setActiveDocuments([]));
                    store.dispatch(GenericActions.reloadRequest());
                    setDisableFavoriteButton(false);
                  });
                }
              }
            },
          },
        ],
        rightButtons: [],
      },
    },

    //* layout for when multiple rows are selected inside a trash page*/
    {
      name: 'action-on-multi-select-trash-documents',
      content: {
        leftButtons: [
          {
            text: 'Restore Documents',
            iconName: 'SkypeCircleCheck',
            onClick: async () => {
              for (let i = 0; i < documentSearch.activeDocuments.length; i++) {
                let doc: IDocumentItem = documentSearch.activeDocuments[i];

                await ApiService.DocumentController.setDeletedDocument({
                  documentId: doc.documentId.toString(),
                  documentActivation: 1,
                });
              }

              dispatch(DocumentListAction.setActiveDocuments([]));
              dispatch(DocumentListAction.setActiveDocument(null));
              dispatch(GenericActions.reloadRequest());
            },
          },
          {
            text: 'Delete Documents',
            iconName: 'RecycleBin',
            onClick: () => {
              if (documentSearch.activeDocuments.length > 0) {
                setDeleteLevel(3);
                const toDelete = getDocumentsToDelete(currentMultiDocumentsPermissions, documentSearch.activeDocuments).map(
                  (x) => x.documentId
                );
                //setDocumentsToDelete(toDelete);
                setCheckDocumentVersionInfo(false);
                setDocumentIdsToDelete(toDelete);
                setShowTrashModal(true);
              }
            },
          },
        ],
        rightButtons: [
          {
            text: documentSearch.activeDocuments.length.toString() + ' Items selected',
            iconName: 'Cancel',
            onClick: () => {
              sessionStorage.removeItem('file-table-last-selection');
              store.dispatch(DocumentListAction.setActiveDocument(null));
              store.dispatch(DocumentListAction.setActiveDocuments([]));
              if (props.onExitAction) {
                props.onExitAction();
              }
            },
          },
        ],
      },
    },

    //** layout for when multiple rows are selected inside the file list */
    {
      name: 'action-on-multi-select-documents',
      content: {
        leftButtons: [
          {
            text: 'Move to Trash',
            iconName: 'RecycleBin',
            onClick: () => {
              if (documentSearch.activeDocuments.length > 0) {
                setDeleteLevel(2);
                /*
                loadDocumentDeletingDocumentVersionInfo(
                  documentSearch.activeDocuments.map((x: IDocumentItem, i: number) => {
                    return x.documentId;
                  })
                );*/
                setCheckDocumentVersionInfo(true);
                setDocumentIdsToDelete(
                  documentSearch.activeDocuments.map((x: IDocumentItem, i: number) => {
                    return x.documentId;
                  })
                );
                setShowTrashModal(true);
              }
            },
          },
          {
            text: 'Download all',
            iconName: 'Download',
            onClick: () => {
              if (documentSearch.activeDocuments.length > 0) {
                setShowDownloadModal(true);
                setDocumentIDsToDownload(documentSearch.activeDocuments.map((x) => x.documentId));
              }
            },
          },
          {
            text: 'Add to favorites',
            iconName: 'SingleBookmark',
            disabled: location.pathname === AppRoutes.STARRED_ROUTE,
            onClick: () => {
              if (documentSearch.activeDocuments.length > 0) {
                const documentsIds = documentSearch.activeDocuments.map((x) => x.documentId);
                ApiService.DocumentController.addFavoriteDocument(documentsIds, (res: IAPIResponse) => {
                  /** trick to force the Drive page reload */
                  dispatch(DocumentListAction.setActiveDocument(null));
                  dispatch(DocumentListAction.setActiveDocuments([]));
                  store.dispatch(GenericActions.reloadRequest());
                });
              }
            },
          },
          {
            text: 'Remove from favorites',
            iconName: 'SingleBookmark',
            onClick: () => {
              if (documentSearch.activeDocuments.length > 0) {
                const documentsIds = documentSearch.activeDocuments.map((x) => x.documentId);
                ApiService.DocumentController.deleteFavoriteDocument(documentsIds, (res: IAPIResponse) => {
                  /** trick to force the Drive page reload */
                  dispatch(DocumentListAction.setActiveDocument(null));
                  dispatch(DocumentListAction.setActiveDocuments([]));
                  store.dispatch(GenericActions.reloadRequest());
                });
              }
            },
          },
        ],
        rightButtons: [
          {
            text: documentSearch.activeDocuments.length.toString() + ' Items selected',
            iconName: 'Cancel',
            onClick: () => {
              sessionStorage.removeItem('file-table-last-selection');
              store.dispatch(DocumentListAction.setActiveDocument(null));
              store.dispatch(DocumentListAction.setActiveDocuments([]));
              if (props.onExitAction) {
                props.onExitAction();
              }
            },
          },
        ],
      },
    },
    //** layout for when a document has been clicked on the page (from a list or gallery) */
    {
      name: 'actions-on-document',
      content: {
        leftButtons: [
          {
            text: 'Open',
            iconName: 'Page',
            disabled: currentDocumentPermissions ? !currentDocumentPermissions.canRead : true,
            onClick: () => {
              if (documentId) {
                navigate(AppRoutes.DOCUMENT_MANAGEMENT + '?documentid=' + documentId.toString());
              }
            },
          },
          {
            text: 'Edit Attributes',
            iconName: 'Edit',
            disabled: currentDocumentPermissions ? !currentDocumentPermissions.canEdit || currentDocumentPermissions.canEditMetadata : true,
            onClick: () => {
              if (documentId) {
                navigate(AppRoutes.DOCUMENT_CREATION_ROUTE + '?documentid=' + documentId.toString() + '&mode=edit');
              }
            },
          },
          {
            text: 'New Version',
            iconName: 'VersionControlPush',
            disabled: currentDocumentPermissions ? !currentDocumentPermissions.canCreateNewVersion : true,
            onClick: () => {
              if (documentId) {
                ApiService.DocumentController.generateDocumentSnapshot(documentId, (response: IAPIResponse) => {
                  if (response.error === null) {
                    localStorage.removeItem('new-document-lock-url');
                    navigate(
                      AppRoutes.DOCUMENT_CREATION_ROUTE + '?documentid=' + response.payload + '&mode=newversion&previousId=' + documentId
                    );
                  } else {
                    if (response.raw.status === 404) {
                      window.document.dispatchEvent(
                        new CustomEvent('api-toast-result', {
                          detail: {
                            text: 'Cannot create New Version: this document already has a new version.',
                            type: 'error',
                          },
                        })
                      );
                    }
                  }
                });
              }
            },
          },
          {
            text: 'Move to Trash',
            iconName: 'RecycleBin',
            disabled: currentDocumentPermissions ? !currentDocumentPermissions.canDelete : true,
            onClick: () => {
              if (documentId) {
                setDeleteLevel(2);
                setDocumentIdsToDelete([documentId]);
                setShowTrashModal(true);
              }
            },
          },
          {
            text: 'Download',
            disabled: documentSearch?.activeDocument?.mainFile === null,
            iconName: 'Download',
            onClick: () => {
              setShowDownloadModal(true);
              setDocumentIDsToDownload([documentSearch.activeDocument?.documentId]);
            },
          },
          {
            text: documentIsFavorite ? 'Remove from Favourites' : 'Add to Favourites',
            iconName: 'SingleBookmark',
            inactive: disableFavoriteButton,
            onClick: () => {
              if (loggedUser && documentId && !disableFavoriteButton) {
                setDisableFavoriteButton(true);
                if (documentIsFavorite) {
                  ApiService.DocumentController.deleteFavoriteDocument([documentId.toString()], (res: IAPIResponse) => {
                    /** trick to force the Drive page reload */
                    dispatch(DocumentListAction.setActiveDocument(null));
                    dispatch(DocumentListAction.setActiveDocuments([]));
                    store.dispatch(GenericActions.reloadRequest());
                    setDisableFavoriteButton(false);
                  });
                } else {
                  ApiService.DocumentController.addFavoriteDocument([documentId.toString()], (res: IAPIResponse) => {
                    /** trick to force the Drive page reload */
                    dispatch(DocumentListAction.setActiveDocument(null));
                    dispatch(DocumentListAction.setActiveDocuments([]));
                    store.dispatch(GenericActions.reloadRequest());
                    setDisableFavoriteButton(false);
                  });
                }
              }
            },
          },
        ],
        rightButtons: [
          {
            text: '1 Item selected',
            iconName: 'Cancel',
            onClick: () => {
              sessionStorage.removeItem('file-table-last-selection');
              store.dispatch(DocumentListAction.setActiveDocument(null));
              if (props.onExitAction) {
                props.onExitAction();
              }
            },
          },
        ],
      },
    },
    {
      name: 'actions-on-trash-document',
      content: {
        leftButtons: [
          {
            text: 'Restore Document',
            iconName: 'SkypeCircleCheck',
            onClick: () => {
              ApiService.DocumentController.setDeletedDocument(
                {
                  documentId: documentId.toString(),
                  documentActivation: 1,
                },
                (res: IAPIResponse) => {
                  dispatch(DocumentListAction.setActiveDocument(null));
                  dispatch(GenericActions.reloadRequest());
                }
              );
            },
          },
          {
            text: 'Delete Document',
            iconName: 'RecycleBin',
            disabled: currentDocumentPermissions ? !currentDocumentPermissions.canDelete : true,
            onClick: () => {
              if (documentId) {
                setDeleteLevel(3);
                //setDocumentsToDelete([documentId]);
                setCheckDocumentVersionInfo(false);
                setDocumentIdsToDelete([documentId]);
                setShowTrashModal(true);
              }
            },
          },
        ],
        rightButtons: [
          {
            text: '1 Item selected',
            iconName: 'Cancel',
            onClick: () => {
              sessionStorage.removeItem('file-table-last-selection');
              store.dispatch(DocumentListAction.setActiveDocument(null));
              if (props.onExitAction) {
                props.onExitAction();
              }
            },
          },
        ],
      },
    },
    //Questo è il trashbin per l'administratore
    {
      name: 'actions-on-trash-document-admin',
      content: {
        leftButtons: [
          {
            text: 'Restore Document',
            iconName: 'SkypeCircleCheck',
            onClick: async () => {
              let toRestore = [];
              for (let i = 0; i < documentSearch.activeDocuments.length; i++) {
                let doc: IDocumentItem = documentSearch.activeDocuments[i];
                toRestore.push(doc.documentId.toString());
              }

              if (toRestore.length === 0) {
                toRestore.push(documentId.toString());
              }

              for (let i = 0; i < toRestore.length; i++) {
                await ApiService.DocumentController.setDeletedDocument({
                  documentId: toRestore[i],
                  documentActivation: 1,
                });
              }

              dispatch(DocumentListAction.setActiveDocument(null));
              dispatch(DocumentListAction.setActiveDocuments([]));
              dispatch(GenericActions.reloadRequest());
            },
          },
          {
            text: 'Delete Document',
            iconName: 'RecycleBin',
            disabled: currentDocumentPermissions ? !currentDocumentPermissions.canDelete : true,
            onClick: () => {
              let toDelete = [];
              if (documentSearch.activeDocuments.length > 0) {
                for (let i = 0; i < documentSearch.activeDocuments.length; i++) {
                  let doc: IDocumentItem = documentSearch.activeDocuments[i];
                  for (let j = 0; j < currentMultiDocumentsPermissions.length; j++) {
                    if (
                      currentMultiDocumentsPermissions[i].canDelete &&
                      currentMultiDocumentsPermissions[i].documentId === doc.documentId
                    ) {
                      toDelete.push(doc.documentId);
                      break;
                    }
                  }
                }
              }

              if (toDelete.length === 0) {
                toDelete.push(documentId);
              }

              if (documentId) {
                setDeleteLevel(4);
                //setDocumentsToDelete(toDelete);
                setCheckDocumentVersionInfo(false);
                setDocumentIdsToDelete(toDelete);
                setShowTrashModal(true);
              }
            },
          },
        ],
        rightButtons: [
          {
            text: documentSearch.activeDocuments.length.toString() + ' Items selected',
            iconName: 'Cancel',
            onClick: () => {
              sessionStorage.removeItem('file-table-last-selection');
              store.dispatch(DocumentListAction.setActiveDocument(null));
              store.dispatch(DocumentListAction.setActiveDocuments([]));
              if (props.onExitAction) {
                props.onExitAction();
              }
            },
          },
        ],
      },
    },
    //** simple upload button layout */
    {
      name: 'navigation-voices',
      content: {
        leftButtons: [
          {
            text: 'Upload',
            iconName: 'Upload',
            disabled: permissions ? !permissions.canCreate : true,
            onClick: () => {
              navigate(AppRoutes.DOCUMENT_CREATION_ROUTE);
            },
          },
          {
            text: 'Massive Import',
            iconName: 'Upload',
            disabled: permissions ? !permissions.elementTypes.includes('MULTIPLE_UPLOAD') : true,
            onClick: () => {
              navigate(AppRoutes.MASSIVE_IMPORT_ROUTE);
            },
          },
          {
            text: 'My Documents',
            disabled: permissions ? !permissions.canCreate : true,
            iconName: 'DocumentSet',
            onClick: () => {
              navigate(AppRoutes.MY_DOCUMENTS_ROUTE);
            },
          },
          {
            text: 'Tasks',
            iconName: 'Ringer',
            onClick: () => {
              navigate(AppRoutes.ACTIVITIES_ROUTE);
            },
          },
          {
            text: 'Favourites',
            iconName: 'SingleBookmark',
            onClick: () => {
              navigate(AppRoutes.STARRED_ROUTE);
            },
          },
          {
            text: 'Admin',
            iconName: 'Admin',
            disabled: permissions ? !permissions.canAccessAdminArea : true,
            onClick: () => {
              navigate(AppRoutes.ADMIN_ROUTE);
            },
          },
        ],
        rightButtons: [
          {
            text: 'Trash',
            iconName: 'RecycleBin',
            onClick: () => {
              navigate(AppRoutes.TRASH_ROUTE);
            },
          },
        ],
      },
    },
    {
      name: 'massive-import-actions-on-file',
      content: {
        leftButtons: [
          {
            text: 'Delete',
            iconName: 'Delete',
            disabled: false,
            onClick: () => {
              const filesToDelete = massiveImportState.filePage.activeFiles;
              if (filesToDelete.length > 0) {
                const fileNamesToDelete = filesToDelete.map((file) => file.fileName);
                ApiService.MassiveImportController.deleteFiles(fileNamesToDelete, (response: IAPIResponse) => {
                  if (response.error == null) {
                    sessionStorage.removeItem('file-table-last-selection');
                    dispatch(MassiveImportAction.setActiveFiles([]));
                    dispatch(MassiveImportAction.setStagingArea(response.payload.count));
                    dispatch(MassiveImportAction.deleteFiles(filesToDelete));
                    if (massiveImportState.stagingArea.metadataValidCount > 0 || massiveImportState.stagingArea.metadataNotValidCount > 0) {
                      dispatch(MassiveImportAction.setNeedRefreshMetadata(true));
                    }
                  }
                });
              }
            },
          },
        ],
        rightButtons: [
          {
            text:
              massiveImportState.filePage.activeFiles.length === 1
                ? '1 Item selected'
                : massiveImportState.filePage.activeFiles.length + ' Items selected',
            iconName: 'Cancel',
            onClick: () => {
              sessionStorage.removeItem('file-table-last-selection');
              dispatch(MassiveImportAction.setActiveFiles([]));
              if (props.onExitAction) {
                props.onExitAction();
              }
            },
          },
        ],
      },
    },
    //* layout when one new is selected */
    {
      name: 'admin-page-one-new-selected',
      content: {
        leftButtons: [
          {
            text: 'Edit new',
            iconName: 'Edit',
            onClick: async () => {
              dispatch(AdminAction.setEditNewItem(newsState.selectedNews[0]));
              dispatch(AdminAction.setShowEditModal(true));
            },
          },
          {
            text: 'Delete New',
            iconName: 'RecycleBin',
            onClick: () => {
              dispatch(AdminAction.setShowDeleteModal(true));
            },
          },
        ],
        rightButtons: [
          {
            text: newsState.selectedNews.length.toString() + ' Items selected',
            iconName: 'Cancel',
            onClick: () => {
              dispatch(AdminAction.setSelectedNews([]));
              dispatch(AdminAction.SetUnselectAllNews(true));
              if (props.onExitAction) {
                props.onExitAction();
              }
            },
          },
        ],
      },
    },
    //* layout when multiple news are selected */
    {
      name: 'admin-page-many-news-selected',
      content: {
        leftButtons: [
          {
            text: 'Delete selected news',
            iconName: 'RecycleBin',
            onClick: async () => {
              dispatch(AdminAction.setShowDeleteModal(true));
            },
          },
        ],
        rightButtons: [
          {
            text: newsState.selectedNews.length.toString() + ' Items selected',
            iconName: 'Cancel',
            onClick: () => {
              dispatch(AdminAction.setSelectedNews([]));
              dispatch(AdminAction.SetUnselectAllNews(true));
              if (props.onExitAction) {
                props.onExitAction();
              }
            },
          },
        ],
      },
    },
    {
      name: 'admin-page-actions-on-library',
      content: {
        leftButtons: [
          {
            text: 'Edit',
            iconName: 'Edit',
            disabled: false,
            onClick: () => {
              navigate(`${AppRoutes.NEW_LIB_PAGE}?libraryId=${adminReduxState.libraryTab.selectedLibrary.id}`);
              dispatch(AdminAction.setSelectedLibraries(undefined));
            },
          },
          {
            text: 'Mark as ready to deploy',
            iconName: 'Upload',
            disabled: adminReduxState.libraryTab.selectedLibrary?.exportStatus !== IExportStatus.WorkInProgress,
            onClick: () => {
              sessionStorage.removeItem('file-table-last-selection');
              dispatch(AdminAction.setSelectedLibraries(undefined));
              ApiService.LibraryController.updateLibraryExportStatus(
                adminReduxState.libraryTab.selectedLibrary.id,
                (response: IAPIResponse) => {
                  if (response.error === null) {
                    dispatch(GenericActions.reloadRequest());
                  }
                }
              );
            },
          },
        ],
        rightButtons: [
          {
            text: '1 Item selected',
            iconName: 'Cancel',
            onClick: () => {
              sessionStorage.removeItem('file-table-last-selection');
              dispatch(AdminAction.setSelectedLibraries(undefined));
              if (props.onExitAction) {
                props.onExitAction();
              }
            },
          },
        ],
      },
    },
  ];

  let wantedLayout = props.type;

  let activeLayout: any = null;

  if (permissions) {
    for (let i = 0; i < knownMenuLayouts.length; i++) {
      let layout = knownMenuLayouts[i];
      if (layout.name === wantedLayout) {
        activeLayout = {...layout};
      }
    }

    if (props.customLayout) {
      activeLayout = {...props.customLayout};
    }
  }

  let modalTitle = 'Move to Trash';
  let modalMessage = 'Documents deleted in this way are moved to the User Trash Bin. Proceed anyway?';

  if (deleteLevel === 3) {
    modalTitle = 'Delete document?';
    modalMessage = 'Documents deleted in this way are deleted and removed from the trash page. Proceed anyway?';
  }

  if (deleteLevel === 4) {
    modalTitle = 'Delete phisically?';
    modalMessage =
      'Documents deleted in this way are removed from the system and are physically deleted. This action cannot be undone. Proceed anyway?';
  }

  let exportSelectedDocuments = documentSearch.activeSearchResults.length > 0;

  return (
    <div>
      <ModalDialog
        modalTitle={'Export search results'}
        modalInnerComponent={
          <div style={{width: '670px'}}>
            You can export up to 100.000 documents. In case you decide to export 1000 or less documents the download will start immediately,
            otherwise you will receive an email with the link to download the export.
            <br />
            <b>Note: </b> temporarily documents with date before 01/01/1970, in the excel file will not have any date, but when you open the
            file you will see it.
            <br />
            {exportSelectedDocuments ? (
              <FluentUIDecorator
                label="Selected Document Number To Export"
                inlineLabel={true}
                info={getInputInfos('search-page', 'selected-document-number-to-export')}
                errorMessage={(window as any)['highlight-errors'] === 1 ? 'This field is required' : undefined}
                fluentComponent={FluentUIDecoratorTypes.TextField({
                  value: documentSearch.activeSearchResults.length.toString(),
                  disabled: true,
                })}
              />
            ) : (
              <FluentUIDecorator
                label="Document Number To Export"
                inlineLabel={true}
                info={getInputInfos('search-page', 'document-number-to-export')}
                errorMessage={(window as any)['highlight-errors'] === 1 ? 'This field is required' : undefined}
                fluentComponent={FluentUIDecoratorTypes.SpinButton({
                  min: 1,
                  max: 100000,
                  step: 10,
                  value: numberDocumentsToExport.toString(),
                  onChange: (event: React.SyntheticEvent<HTMLElement>, newValue?: string) => {
                    if (newValue !== undefined) {
                      setNumberDocumentsToExport(Number(newValue));
                    }
                  },
                })}
              />
            )}
          </div>
        }
        enableModal={showSearchExport}
        onAbort={() => {
          setShowSearchExport(false);
        }}
        loadingButtons={searchExportLoading}
        modalButtons={[
          {
            onClick: () => {
              setShowSearchExport(false);
            },
            label: 'Cancel',
          },
          {
            label: 'Export',
            disabled: numberDocumentsToExport === 0 || numberDocumentsToExport > 100000,
            onClick: () => {
              setSearchExportLoading(true);
              const searchBody: string = URIformatter.decode(params.get('searchBody') ?? '');
              let body = JSON.parse(searchBody);
              body['limit'] = exportSelectedDocuments ? documentSearch.resultsLimit : numberDocumentsToExport;
              body['sortBy'] = exportSelectedDocuments ? generic.sortSearchBy : 'documentId';
              body['sortDesc'] = generic.sortDirectionDesc ;
              body['DocumentIds'] = exportSelectedDocuments ? documentSearch.activeSearchResults.map((x) => x.documentId) : null;
              ApiService.DownloadController.searchExport(body, true, (response: IAPIResponse) => {
                setShowSearchExport(false);
                setSearchExportLoading(false);
              });
            },
          },
        ]}
      />
      <ModalDialog
        modalTitle={'Revert to Draft?'}
        modalInnerComponent={
          <div>
            Are you sure you want to revert this document to Draft? By doing this, any currently active Workflow will be cancelled, and all
            progress will be lost.
            <br />
            <br />
            This action cannot be undone. Proceed anyway?
          </div>
        }
        enableModal={showRevertModal}
        onAbort={() => {
          setShowRevertModal(false);
        }}
        onAccept={async () => {
          setShowRevertModal(false);
          ApiService.DocumentController.revertDocument(documentId, (response: IAPIResponse) => {
            if (response.error === null) {
              navigate(AppRoutes.DOCUMENT_CREATION_ROUTE + '?documentid=' + documentId.toString() + '&mode=edit');
            }
          });
        }}
      />
      <ModalDialog
        modalTitle="Share this document"
        enableModal={showShareModal}
        modalInnerComponent={
          <div>
            <EmailPicker
              onChange={(list: string[]) => {
                setEmailsList(list);
              }}
            />
          </div>
        }
        loadingButtons={sharing}
        modalButtons={[
          {
            onClick: () => {
              setShowShareModal(false);
            },
            label: 'Cancel',
          },
          {
            label: 'Share',
            disabled: emailsList.length === 0,
            onClick: () => {
              let documentId = params.get('documentid') ?? documentSearch.activeDocument?.documentId;
              if (emailsList.length > 0 && documentId) {
                setSharing(true);
                ApiService.DocumentController.shareDocuments(emailsList, documentId, (response: IAPIResponse) => {
                  setSharing(false);
                  if (response.error === null) {
                    setShowShareModal(false);
                  }
                });
              }
            },
          },
        ]}
        onAbort={() => {
          setShowShareModal(false);
        }}
      />
      <ModalDialog
        modalTitle={`Your are asking to revert the current document on draft on ${props.currentDocument?.sourceAppName} are you sure you want to continue?`}
        enableModal={showEditOnModal}
        modalInnerComponent={<></>}
        loadingButtons={showEditOnModalLoading}
        modalButtons={[
          {
            onClick: () => {
              setShowEditOnModal(false);
            },
            label: 'Cancel',
          },
          {
            label: `Edit on ${props.currentDocument?.sourceAppName}`,
            onClick: async () => {
              setShowEditOnModalLoading(true);

              const result = await ApiService.DocumentController.setDeletedDocument({
                documentId: props.currentDocument.documentId,
                documentActivation: 2,
              });
              if (result.error !== null) {
                if (result.payload?.title === "User's Trash Bin is full") {
                  window.document.dispatchEvent(new Event('trashBinFullModal'));
                }
              } else {
                window.location.href = props.currentDocument.redirectEditUrl;
              }
            },
          },
        ]}
        onAbort={() => {
          setShowEditOnModal(false);
        }}
      />
      <MoveToTrashSelectedDocuments
        showModal={showTrashModal}
        setShowModal={setShowTrashModal}
        deleteLevel={deleteLevel}
        setDeleteLevel={setDeleteLevel}
        documentIdsToDelete={documentIdsToDelete}
        checkDocumentVersionInfo={checkDocumentVersionInfo}
      />
      <DownloadDocumentModal
        enableModal={showDownloadModal}
        onClose={() => {
          setDocumentIDsToDownload([]);
          setShowDownloadModal(false);
        }}
        documentIds={documentIDsToDownload}
      />
      <DocumentManagementToolbar loading={loading} layout={activeLayout} />
    </div>
  );
};
