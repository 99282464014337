import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { IBaseLibraries } from '../../Models/IUser';
import AppRoutes from '../../Utils/AppRoutes';
import VideoTutorialIcon from '../VideoTutorialIcon/VideoTutorialIcon';
import './UserInfoTable.scss';
import UserInfoTableLibraryRow from './UserInfoTableLibraryRow';
import {ILibrary} from '../../Models/ILibrary';

interface UserInfoTableProps {
  userId: string;
  name: string;
  email: string;
  lastLogin: string;
  digitalSignature: string | JSX.Element;
  libraries: IBaseLibraries[];
  adminLibraries?: ILibrary[];
  adminSectionOnSave?: (userId: string) => void;
}

export const UserInfoTable = (props: UserInfoTableProps) => {
  const {pathname} = useLocation();

  let isUserInfoPage = pathname === AppRoutes.USER_INFO ? true : false;

  useEffect(() => {
    if (localStorage.getItem('tell-form-was-ok') !== null) {
      localStorage.removeItem('tell-form-was-ok');

      window.document.dispatchEvent(
        new CustomEvent('api-toast-result', {
          detail: {
            text: 'Your request has been submitted.',
            type: 'success',
          },
        })
      );
    }
  }, []);

  if (localStorage.getItem('user-not-enabled') === 'true') {
    isUserInfoPage = true;
  }

  return (
    <div
      className="user-info-table-wrapper"
      style={isUserInfoPage ? {} : {border: 'none', boxShadow: 'none', width: '100%', maxWidth: '100%', padding: '0'}}>
      {isUserInfoPage && (
        <h1>
          Info
          <VideoTutorialIcon />
        </h1>
      )}
      <table className="user-info-table-table">
        <thead className="user-info-table-table-head">
          <tr role={'row'}>
            <th role={'columnheader'}>UserID</th>
            <th role={'columnheader'}>Name</th>
            <th role={'columnheader'}>E-mail</th>
            <th role={'columnheader'}>Last Login</th>
            <th role={'columnheader'}>Digital Signature</th>
          </tr>
        </thead>
        <tbody className="user-info-table-table-body">
          <tr role={'row'}>
            <td role={'columnheader'}>{props.userId}</td>
            <td role={'columnheader'}>{props.name}</td>
            <td role={'columnheader'}>{props.email}</td>
            <td role={'columnheader'}>{props.lastLogin}</td>
            <td role={'columnheader'}>{props.digitalSignature}</td>
          </tr>
        </tbody>
      </table>

      {props.libraries.length > 0 && (
        <div className="user-info-table-libraries">
          <h2>Libraries</h2>
          {props.libraries?.map((library, i) => (
            <UserInfoTableLibraryRow
              key={i}
              library={library}
              adminLibraries={props.adminLibraries}
              isUserInfoPage={isUserInfoPage}
              userId={props.userId}
              adminSectionOnSave={props.adminSectionOnSave}
            />
          ))}
        </div>
      )}
    </div>
  );
};
