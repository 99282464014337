import {IPersonaSharedProps, Persona, PersonaPresence, PersonaSize} from '@fluentui/react';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {GlobalState} from '../../Reducers/RootReducer';
import {IAPIResponse} from '../../Services/AjaxService';
import ApiService from '../../Services/ApiService';
import Icons from '../../Utils/FabricIconsOutlet';
import {useNavigate} from 'react-router-dom';
import './DriveUserMenu.scss';
import AppRoutes from '../../Utils/AppRoutes';
import {AuthenticationHelper, UserMenu} from '@Eni/docware-fe-master';

const DriveUserMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [trashSize, setTrashSize] = useState<number>(0);
  const permissions = useSelector((state: GlobalState) => state.user.permissionsByActions);

  /** this state is only used as hook to request specific actions across the whole site */
  let generic = useSelector((state: GlobalState) => state.generic);

  let user = useSelector((state: GlobalState) => state.user.currentUser);

  const examplePersona: IPersonaSharedProps = {
    imageInitials: user ? (user.firstName ?? '-').charAt(0) + (user.lastName ?? '-').charAt(0) : '',
    text: user ? user.firstName + ' ' + user.lastName : '',
    secondaryText: '', //'Software Engineer',
  };

  useEffect(() => {
    ApiService.DocumentController.getDocumentTrashAmount((response: IAPIResponse) => {
      if (response.error === null) {
        setTrashSize(response.payload);
      }
    });
  }, [generic.reloadRequest]);

  let cleanCountries: any = permissions
    ? permissions.countries?.map((x: any) => {
        return x.name.charAt(0).toUpperCase() + x.name.slice(1).toLocaleLowerCase();
      })
    : [];

  if (cleanCountries && cleanCountries.length > 0) {
    cleanCountries = new Set([...cleanCountries]);
  }

  cleanCountries = Array.from(cleanCountries).join(', ');

  let menuContent = (
    <div>
      <Persona
        {...examplePersona}
        size={PersonaSize.size48}
        hidePersonaDetails={false}
        presence={PersonaPresence.online}
        imageAlt="Annie Lindqvist, no presence detected"
      />
      <div className="user-menu-under-zone">
        <div className="user-menu-under-zone-block">
          <div className="user-menu-under-zone-title">Professional Areas</div>
          <nav className="user-menu-under-zone-content">
            {permissions?.professionalAreas
              ?.map((x: any) => {
                return x.name;
              })
              .join(', ')}
          </nav>
          <div className="user-menu-under-zone-block">
            <div
              className="user-menu-under-zone-block-icon"
              onClick={() => {
                navigate(AppRoutes.USER_INFO);
              }}>
              {Icons.getIcon('Info')}
              <div style={{marginRight: '0.5em'}}></div>
              <div>User Permissions</div>
            </div>
          </div>
        </div>

        <div className="user-menu-under-zone-block">
          <div
            className="user-menu-under-zone-block-icon"
            onClick={() => {
              navigate(AppRoutes.TRASH_ROUTE);
            }}>
            {Icons.getIcon('RecycleBin')}
            <div style={{marginRight: '0.5em'}}></div>
            <div>Deleted Files {trashSize !== 0 ? '(' + trashSize.toString() + ')' : ''}</div>
          </div>
        </div>
        <div className="user-menu-under-zone-block">
          <div
            className="user-menu-under-zone-block-icon"
            onClick={() => {
              ApiService.UsersController.logoutAndRedirect();
            }}>
            {Icons.getIcon('ErrorBadge')}
            <div style={{marginRight: '0.5em'}}></div>
            <div>Logout</div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <UserMenu content={menuContent} />
    </div>
  );
};

export default DriveUserMenu;
