import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';
import {IDocumentQuery} from '../../../Models/IDocument';
import {GlobalState} from '../../../Reducers/RootReducer';
import {useNavigate} from 'react-router-dom';
import {DocumentListAction} from '../../../Reducers/DocumentSearch/DocumentSearchActions';
import {getNestedPropertyByString, removeDuplicatesFromArrayOfObject, setNestedPropertyByString} from '../../../Utils/DictUtils';
import URIformatter from '../../../Utils/URIformatter';
import SearchTagsControl from '../../../Components/SearchTagsControl/SearchTagsControl';
import {Spinner} from '@fluentui/react';
import DocumentSearchResumeBox from '../../../Components/DocumentSearchResumeBox/DocumentSearchResumeBox';
import {LabelButton} from '@Eni/docware-fe-master';
import {SEARCH_BASE_PAGINATION_INCREMENT} from '../../../Constants/Constants';
import {getSearchBaseUrl, SEARCH_SCROLLABLE_CONTENT_ID, SearchPagePropsData, setQueryStringWithDefaults} from '../DocumentSearchPage';
import {IValue} from '../../../Models/IValue';
import {BoUtils} from '../../../Utils/BoUtils';
import VideoTutorialIcon from '../../../Components/VideoTutorialIcon/VideoTutorialIcon';
import {useNavigateToSearchAndReset} from '../../../Hooks/useNavigateToSearchAndReset';

interface DocumentSearchAllProps extends SearchPagePropsData {
  documents: IDocumentQuery[];
}

const DocumentSearchAll = (props: DocumentSearchAllProps) => {
  const dispatch = useDispatch();
  const navigateToSearchAndReset = useNavigateToSearchAndReset();
  const scrollPosition = useSelector<GlobalState, number | null>((state) => state.documentSearch.scrollPosition);
  const documentSearch = useSelector((state: GlobalState) => state.documentSearch);

  const searchBodySearch = props.searchBody !== '' ? JSON.parse(props.searchBody).search : '';
  const activeSearchText = searchBodySearch !== '' ? searchBodySearch : '';
  const sortedResults = props.documents.filter((x) => x !== undefined);

  useEffect(() => {
    if (props.documents.length > 0 && scrollPosition) {
      const scrollableContent = document.querySelector(`#${SEARCH_SCROLLABLE_CONTENT_ID}`);
      setTimeout(() => {
        scrollableContent.scrollTop = scrollPosition;
        dispatch(DocumentListAction.setScrollPosition(null));
      }, 1000);
    }
  }, [props.documents]);


  const addFilter = (filters: {filterName: string; filterValue: any}[]) => {
    if (props.searchBody === '') {
      return;
    }

    let obj = JSON.parse(props.searchBody);
    filters.forEach(({filterName, filterValue}) => {
      if (['document.bOs'].includes(filterName)) {
        const currentBOs = getNestedPropertyByString(obj, filterName) ?? {};
        const boMerged = BoUtils.getBoMerged(currentBOs, filterValue);
        obj = setNestedPropertyByString(obj, filterName, boMerged);
      } else {
        obj = setNestedPropertyByString(obj, filterName, filterValue);
      }
    });

    obj = setQueryStringWithDefaults(obj);
    navigateToSearchAndReset(`${getSearchBaseUrl()}?searchBody=${URIformatter.encode(JSON.stringify(obj))}`);
  };

  const removeFilter = (paths: string[], isLast: boolean) => {
    if (props.searchBody === '') {
      return;
    }

    let obj = JSON.parse(props.searchBody);

    for (const path of paths) {
      let stepObj = obj;
      let splPath = path.split('.');
      for (let i = 0; i < splPath.length; i++) {
        let step: string = splPath[i];

        /** last step */
        if (i === splPath.length - 1) {
          delete stepObj[step];
        } else {
          stepObj = stepObj[step];
        }
      }
    }

    let simpleSearchToggle = isLast ? '&simpleSearch=yes' : '';

    obj = setQueryStringWithDefaults(obj);
    navigateToSearchAndReset(`${getSearchBaseUrl()}?searchBody=${URIformatter.encode(JSON.stringify(obj)) + simpleSearchToggle}`);
  };

  const simpleSearchError = props.simpleSearch === 'yes' && activeSearchText.length <= 3;

  return (
    <div className="search-left-column-wrap-background">
      <nav className="search-left-column-wrap" id={SEARCH_SCROLLABLE_CONTENT_ID}>
        <div className="search-left-column-wrap-inner">
          {(simpleSearchError || sortedResults.length === 0) && (
            <div className="document-search-header">
              Search documents using the search bar above.
              <VideoTutorialIcon style={{"bottom": "4px", "marginLeft": "5px"}} iconFontSize='28px'/>
            </div>
          )}
          {!simpleSearchError && sortedResults.length > 0 && (
            <div className="document-search-header-wrap">
              <div className="document-search-header">
                Search results based on the following criteria:
                <VideoTutorialIcon style={{"bottom": "4px", "marginLeft": "5px"}} iconFontSize='28px'/>
              </div>
              <div className="document-search-header">
                Results:{' '}
                <div className="document-search-text">
                  {props.loading ? '-' : sortedResults.length.toString() + ' of ' + props.maxResults.toString()}
                </div>
              </div>
            </div>
          )}

          {props.searchBody !== '' && (
            <div>
              <SearchTagsControl
                query={props.searchBody}
                onRemove={(path: string, isLast: boolean) => {
                  let filterToRemove: string[] = path.split(';');
                  removeFilter(filterToRemove, isLast);
                }}
              />
            </div>
          )}

          <div className="document-search-boxes-wrap">
            {props.loading && (
              <div className="search-spinner-wrap-main">
                <div className="search-spinner-inner">
                  <Spinner label="Search in progress..." />
                </div>
              </div>
            )}
            {!props.loading && (
              <div>
                {!simpleSearchError &&
                  sortedResults.map((x: IDocumentQuery, i: number) => {
                    return (
                      <DocumentSearchResumeBox
                        item={x.document}
                        key={i}
                        relatedDocument={null}
                        highlights={x.highlights}
                        onItemSelected={(item: any) => {
                          props.onSelectedItem(item, i);
                        }}
                        onAddFilters={addFilter}
                      />
                    );
                  })}

                {!simpleSearchError && sortedResults.length > 0 && sortedResults.length < props.maxResults && (
                  <div className="favorites-page-centered-button">
                    <LabelButton
                      whiteOutlined
                      icon="ChevronDown"
                      text="Load more results"
                      onClick={() => {
                        dispatch(DocumentListAction.setSearchLimit(documentSearch.resultsLimit + SEARCH_BASE_PAGINATION_INCREMENT));
                        dispatch(DocumentListAction.setActiveSearchResults([]));
                      }}
                    />
                  </div>
                )}

                <div>
                  {!simpleSearchError && props.documents.filter((x) => x !== undefined).length === 0 && (
                    <div className="search-no-results-label">The search did not produce a result.</div>
                  )}
                  {simpleSearchError && (
                    <div className="search-no-results-label">
                      To perform a basic search, your search text must be longer than 3 characters or you must use any other advanced search
                      criteria.
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};
export default DocumentSearchAll;
