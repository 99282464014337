import { useEffect, useState } from 'react';
import pdfTypeSubtypeFile from '../../assets/pdf_type_subtype.pdf';
import ApiService from '../../Services/ApiService';
import { IAPIResponse } from '../../Services/AjaxService';
import { getCurrentDateFormatted } from '../../Utils/DateUtils';

export enum AnchorToApiType {
  pdfTypeSubtype,
}

interface AnchorToApiSpec {
  fileLink: string;
  fileName: string;
  displayName: string;
}

export const getAnchorToApiTypeSpec = (anchorToApiType: AnchorToApiType): AnchorToApiSpec | any => {
  switch (anchorToApiType) {
    case AnchorToApiType.pdfTypeSubtype:
      return { fileLink: pdfTypeSubtypeFile, fileName: `Subsurface_docsubtype_${getCurrentDateFormatted()}.xlsx`, displayName: 'Document Type' };
  }
};

interface AnchorToFileProps {
  file: AnchorToApiSpec;
}

export const AnchorToApi = (props: AnchorToFileProps) => {
  const [base64, setBase64] = useState<string>();

  useEffect(() => {
    ApiService.DownloadController.downloadTableOfDocumentSubType((response: IAPIResponse) => {
      if (response.error === null) {
        setBase64(response.payload.data);
      }
    });
  }, []);

  return (
    // <a href={`data:application/pdf;base64,${base64}`}>{"Document Types"}</a>
    // <a href={''} onClick={props.onClick}>
    //   {"Document Types"}
    // </a>
    <a href={`data:application/pdf;base64,${base64}`} download={props.file.fileName} target="_blank">
      {props.file.displayName}
    </a>
  );
};
