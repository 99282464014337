import {ModalDialog} from '@Eni/docware-fe-master';
import {Checkbox} from '@fluentui/react';
import {useState, useRef, useMemo} from 'react';
import ApiService from '../../Services/ApiService';
import {APISettings} from '../../Services/AjaxService';

interface DownloadDocumentModalProps {
  enableModal: boolean;
  onClose: () => void;
  documentIds: string[];
}

const DownloadDocumentModal = ({enableModal, onClose, documentIds}: DownloadDocumentModalProps) => {
  const [downloadOriginalFile, setDownloadOriginalFile] = useState<boolean>(false);
  const [downloadSignedFile, setDownloadSignedFile] = useState<boolean>(false);
  const [downloadAttachments, setDownloadAttachments] = useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const anchorRef = useRef<HTMLAnchorElement>(null);
  const documentId = useMemo(() => {
    if (documentIds) return documentIds[0];
  }, [documentIds]);

  const downloadButtonEnable = downloadOriginalFile || downloadSignedFile || downloadAttachments;
  return (
    <>
      <a
        style={{display: 'none'}}
        ref={anchorRef}
        href={`${APISettings.baseUrl}/download/documents/${downloadOriginalFile ? 'original' : 'signed'}/direct-download/${documentId}`}
        download
        target = "_blank"
      />

      <ModalDialog
        modalTitle={'Select what you want download'}
        modalInnerComponent={
          <div>
            <div style={{display: 'flex', flexDirection: 'column', gap: '1rem', padding: '1rem'}}>
              <Checkbox
                label="Original File"
                checked={downloadOriginalFile}
                onChange={(e, checked?: boolean) => {
                  setDownloadOriginalFile(!!checked);
                }}
              />
              <Checkbox
                label="Signed file"
                checked={downloadSignedFile}
                onChange={(e, checked?: boolean) => {
                  setDownloadSignedFile(!!checked);
                }}
              />
              <Checkbox
                label="Attachments"
                checked={downloadAttachments}
                onChange={(e, checked?: boolean) => {
                  setDownloadAttachments(!!checked);
                }}
              />
            </div>
          </div>
        }
        enableModal={enableModal}
        onAbort={() => {
          onClose();
        }}
        loadingButtons={downloadLoading}
        modalButtons={[
          {
            label: 'Cancel',
            onClick: () => {
              onClose();
            },
          },
          {
            label: 'Download',
            disabled: !downloadButtonEnable,
            onClick: async () => {
              setDownloadLoading(true);
              // se e' c'e solo un file ed ho premuto solo o downloadOriginalFile o downloadOriginalFile
              if (documentIds.length === 1
                && (downloadOriginalFile && !downloadSignedFile) || (!downloadOriginalFile && downloadSignedFile)
                && !downloadAttachments
              ) {
                  anchorRef.current.click();
              }
              else {
                await ApiService.DownloadController.sendEmailToDownloadZip(documentIds, downloadOriginalFile, downloadSignedFile, downloadAttachments);
              }
              setDownloadLoading(false);
              setDownloadAttachments(false);
              setDownloadSignedFile(false);
              setDownloadOriginalFile(false);              
              onClose();
            },
          },
        ]}
      />
    </>
  );
};

export default DownloadDocumentModal;
