import {IUser} from '@microsoft/applicationinsights-common';
import {USERS_URLS} from '../../Constants/Urls';
import {ISendAuthorizationRequest} from '../../Models/IUser';
import {AjaxService, createEmptyRequest, IAPIRequest, IAPIResponse} from './../AjaxService';
import {ITag} from '@fluentui/react';
import AppRoutes from '../../Utils/AppRoutes';
import {AuthenticationHelper} from '@Eni/docware-fe-master';

export interface IUsersQueryObject {
  search: string;
  limit: number;
  offset: number;
}

const UsersController = {
  adminImpersonate: async (userId: string, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = USERS_URLS.ADMIN_IMPERSONATE_USER + userId;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  runPermissionsSync: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = USERS_URLS.RUN_PERM_SYNC;
    request.successMessage = 'Permissions synchronization successfully started... it can take up to 5 minutes for changes to take effect.';
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  adminSetAdminFlag: async (targetUserId: string, isSuperAdmin: boolean, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = USERS_URLS.SET_ADMIN_FLAG;
    request.method = 'POST';
    request.payload = {
      targetUserId: targetUserId,
      isSuperAdmin: isSuperAdmin,
    };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  adminSetEnabledFlag: async (targetUserId: string, isEnabled: boolean, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = USERS_URLS.SET_ENABLED_FLAG;
    request.method = 'POST';
    request.payload = {
      targetUserId: targetUserId,
      isEnabled: isEnabled,
    };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  adminFiltersGet: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = USERS_URLS.GET_USERS_FILTERS_ADMIN;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  sendAuthorizationRequest: async (content: ISendAuthorizationRequest, callback?: (response: any) => void) => {
    console.log({content});
    let request: IAPIRequest = createEmptyRequest();
    request.url = USERS_URLS.POST_SEND_AUTHORIZATION_REQUEST;
    request.method = 'POST';
    request.payload = content;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  getPermissionByAction: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = USERS_URLS.GET_PERMISSIONS_BY_ACTION;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getPermissionRecap: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = USERS_URLS.GET_PERMISSION_RECAP;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getPermissionRecapAdmin: async (userId: string, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = USERS_URLS.GET_PERMISSION_RECAP_ADMIN + '?userId=' + userId;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getLibraries: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = USERS_URLS.GET_BASE_LIBRARIES;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  adminManageUser: async (user: IUser, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = USERS_URLS.ADMIN_MANAGE_USERS;
    request.method = 'post';
    request.payload = user;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getUserById: async (callback?: (response: any) => void, skipAutoLogoutTest: boolean = false) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = USERS_URLS.GET_USER_URL;
    request.skipAutoLogoutTest = skipAutoLogoutTest;

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getAllUsers: async (queryObj: any, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = USERS_URLS.GET_USERS_URL_ADMIN;
    request.method = 'POST';
    request.payload = queryObj;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getUsersAuthorizationRequest: async (queryObject: IUsersQueryObject, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = USERS_URLS.GET_USERS_AUTHORIZATION_REQUEST_URL;
    request.method = 'POST';
    request.payload = queryObject;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getUsers: async (queryObject: IUsersQueryObject, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = USERS_URLS.GET_USERS_URL;
    request.method = 'POST';
    request.payload = queryObject;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getCoOwners: async (
    search: string,
    profAreaId: string,
    countriesId: string[],
    documentSubTypeId: string,
    callback?: (response: any) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = USERS_URLS.GET_CO_OWNERS_URL;
    request.method = 'POST';
    request.payload = {
      search: search,
      professionalAreaId: profAreaId,
      countryIds: countriesId,
      documentSubTypeId: documentSubTypeId,
    };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getUsersByLibrary: async (queryObject: IUsersQueryObject, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = USERS_URLS.GET_USERS_BY_PROFESSIONAL_AREA_URL;
    request.method = 'POST';
    request.payload = queryObject;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  logoutAndRedirect: async () => {
    const request: IAPIRequest = createEmptyRequest();
    request.url = USERS_URLS.LOGOUT;
    const response: IAPIResponse = await AjaxService.call(request);
    if (response.error === null) {
      AuthenticationHelper.logout();

      let kwownVersion = localStorage.getItem('known-version');

      localStorage.clear();

      // clear all cookies
      document.cookie.split(';').forEach(function (c) {
        document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
      });

      localStorage.setItem('known-version', kwownVersion ?? '0');
      localStorage.setItem('do-not-start-the-login', 'true');

      let redirect = '';
      if (response.payload && response.payload.url) {
        redirect = response.payload.url + '?post_logout_redirect_uri=';
      }

      redirect += window.location.origin + AppRoutes.HOME_ROUTE;
      window.location.href = redirect;
    }
    return response;
  },
  saveUserCountries: async (libraryId: string, userId: string, countries: ITag[], callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = '/user/save-countries';
    request.method = 'POST';
    request.payload = {
      libraryId: libraryId,
      userId: userId,
      countries: countries.map((c) => ({countryCode: c.key, countryDescription: c.name})),
    };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  librariesWhereUserCanSearch: async () => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = '/user/libraries-where-user-can-search';
    let response: IAPIResponse = await AjaxService.call(request);
    return response;
  },
};

export default UsersController;
